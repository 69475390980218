html[data-theme='dark'] {
    --background: #1b2338;
    --font-color: #f2f2f2;
    --color-primary: #248fb2;
    --card-background: #222a3e;
    --card-shadow: 0px 0px 24px rgba(5, 10, 26, 0.76);
    --box-background: #222a3e;
    --shadow-color: rgba(5, 10, 26, 0.76);
    --btn-hover-color: #248fb2;
    --btn-text-color: white;
    --border-color: #293044;
    --nav-item-hover-background: #363d50;
    --literal-background: #363d50;
    --placeholder: #f2f2f2;
    --jumbotron-border-color: #3d4158;
    --notification-font-color: white;
    --dark-text: #555555;
    --logo: url(/css/assets/images/logo-light.svg);
    --empty-state: url(/css/assets/images/empty-state-dark.svg);
    --code-string-color: #7ac4dc;
    --code-literal-color: #97da6d;
    --code-number-color: #af95ff;
    --icon-color: #248fb2;
    --icon-font-color: #9d9d9e;
    --aside-border: none;
    --aside-box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    --aside-background-color: #293044;
    --color-post: rgba(13, 186, 80, 0.15);
    --color-post-text: #97da6d;
    --color-get: rgba(9, 136, 239, 0.15);
    --color-get-text: #0988EF;
    --color-put-text: #c5862b;
    --color-delete-text: #f2292f;
    --color-delete: rgba(242, 41, 47, 0.15);
    --footer-color: #222a3e;
    --jumbotron-background: #222a3e;
    --success-bg: #293044;
    --info-bg: #293044;
    --warning-bg: #293044;
    --danger-bg: #293044;
    --link-color: #14c5ff;
    --color-branding-secondary: #00ff89;
    --code-attribute-color: #ffffff;
    --mark-results: #248FB2;
    --category-background-color: #1B2338;
    --all-results-border: #676B73;
    --dropdown-background: #363D50;
    --pagination-border-color: #676B73;
    --result-hover-background: rgb(27, 35, 56, 0.5);
    --disabled-color: #909091;
    --gray-text: #BFC0C3;
    --hover-background-color: rgb(27, 35, 56, 0.5);
    --hover-background-color-icon: #1B2338;
    --icon-number-1: url(/css/assets/images/1-icon-dark.svg) 0px -1px no-repeat;
    --icon-number-2: url(/css/assets/images/2-icon-dark.svg) 0px -1px no-repeat;
    --icon-number-3: url(/css/assets/images/3-icon-dark.svg) 0px -1px no-repeat;
    --icon-number-4: url(/css/assets/images/4-icon-dark.svg) 0px -1px no-repeat;
    --icon-number-5: url(/css/assets/images/5-icon-dark.svg) 0px -1px no-repeat;
    --icon-number-6: url(/css/assets/images/6-icon-dark.svg) 0px -1px no-repeat;
    --icon-number-7: url(/css/assets/images/7-icon-dark.svg) 0px -1px no-repeat;
    --icon-number-8: url(/css/assets/images/8-icon-dark.svg) 0px -1px no-repeat;
    --icon-number-9: url(/css/assets/images/9-icon-dark.svg) 0px -1px no-repeat;
    --icon-number-10: url(/css/assets/images/10-icon-dark.svg) 0px -1px no-repeat;
    --icon-filtered: url(/css/assets/images/dark-filter-icon-with-values.svg) no-repeat center;
    --icon-reset: url(/css/assets/images/refresh-dark.svg);
}
