.numbered {
    font-size: var(--font-size-md) !important;
    font-weight: var(--font-weight-bolder) !important;
    color: var(--notification-font-color);
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 10px;
    font-family: var(--font-family);
}

.numbered:before {
    --size: 26px;
    background-color: var(--color-primary);
    color: white;
    font-size: var(--font-size);
    border-radius: 50%;
    left: calc(-1 * var(--size) - 10px);
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    top: 0;
    text-align: center;
    margin-right: 8px;
    flex-shrink: 0;
}

.numbered.n1:before {
    content: '1';
}

.numbered.n2:before {
    content: '2';
}

.numbered.n3:before {
    content: '3';
}

.numbered.n4:before {
    content: '4';
}

.numbered.n5:before {
    content: '5';
}

.numbered.n6:before {
    content: '6';
}

.numbered.n7:before {
    content: '7';
}

.numbered.n8:before {
    content: '8';
}

.numbered.n9:before {
    content: '9';
}

.numbered.n10:before {
    content: '10';
}
