.hljs,
.hljs-attr {
  color: var(--placeholder) !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.hljs-number {
  color: var(--code-number-color) !important;
}
.hljs-string {
  color: var(--code-string-color) !important;
}
.hljs-literal {
  color: var(--code-literal-color) !important;
}
.hljs-subst, .hljs-attribute {
  color: var(--code-attribute-color) !important;
}
.no-copy .btn-primary {
  display: none;
}
.code-block,
ul.endpoint,
section.endpoint {
  border-radius: 12px;
  padding: 0;
  border: 1px solid var(--jumbotron-border-color);
  background-color: var(--box-background) !important;
}
.code-block button, .code-block button:focus {
  color: var(--icon-color) !important;
}
.code-block.standalone + button, .code-block.standalone + button:focus {
  top: 10px !important;
  right: 10px !important;;
  background-color: transparent !important;
  color: var(--icon-color) !important;
  border: none;
  cursor: pointer;
}
ul.endpoint,
section.endpoint {
  margin-bottom: 15px;
}
ul.endpoint .titlepage,
section.endpoint .titlepage {
  display: none;
}
.code-block-title,
ul.endpoint p:first-of-type,
section.endpoint p:first-of-type {
  border-bottom: 1px solid var(--border-color);
  padding: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background-color: var(--background);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.code-block .hljs,
ul.endpoint .hljs,
section.endpoint .hljs {
  margin: 0;
}
ul.endpoint p:not(:first-of-type),
section.endpoint p:not(:first-of-type) {
  padding: 10px;
}
ul.endpoint li,
.api-method-example li {
  list-style: none;
}
.code-block .paligocode-wrapper .hljs,
ul.endpoint .paligocode-wrapper .hljs,
section.endpoint .paligocode-wrapper .hljs {
  background-color: var(--box-background) !important;
  border-radius: 0 12px 12px 12px;
}
.code-block .paligocode-wrapper,
ul.endpoint .paligocode-wrapper,
section.endpoint .paligocode-wrapper {
  padding: 10px;
}
.code-block button[style] {
  top: -33px !important;
  right: 10px !important;
  background-color: var(--background);
  border: none;
}
.code-block button i {
  color: var(--icon-color);
}
.code-block button:hover,
.code-block button:active,
.code-block button:focus {
  background-color: var(--jumbotron-border-color) !important;
}
.http-post,
.http-get,
.http-put,
.http-delete {
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
}

.http-post::before,
.http-get::before,
.http-put::before,
.http-delete::before {
  padding: 4px 8px;
  border-radius: 100px;
  margin-right: 15px;
  font-size: 10px;
}

.http-post::after,
.http-get::after,
.http-put::after,
.http-delete::after {
  padding: 4px 8px;
  border-radius: 100px;
  margin-left: 15px;
  font-weight: 600;
  font-size: 10px;
}

p.http-post::before {
  content: 'POST';
  background-color: var(--color-post);
  color: var(--color-post-text);
}
p.http-get::before {
  content: 'GET';
  background-color: var(--color-get);
  color: var(--color-get-text);
}
p.http-put::before {
  content: 'PUT';
  background-color: var(--color-put);
  color: var(--color-put-text);
}
p.http-delete::before {
  content: 'DELETE';
  background-color: var(--color-delete);
  color: var(--color-delete-text);
}


.topic-link.http-post,.topic-link.http-put,.topic-link.http-delete,.topic-link.http-get{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topic-link.http-post::after, .topic-link.http-put::after, .topic-link.http-delete::after,  .topic-link.http-get::after{
  height:22px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.topic-link.http-post::after {
  content: 'POST';
  background-color: var(--color-post);
  color: var(--color-post-text);
}

.topic-link.http-put::after {
  content: 'PUT';
  background-color: var(--color-put);
  color: var(--color-put-text);
}

.topic-link.http-delete::after {
  content: 'DELETE';
  background-color: var(--color-delete);
  color: var(--color-delete-text);
}

.topic-link.http-get::after {
  content: 'GET';
  background-color: var(--color-get);
  color: var(--color-get-text);
}

ul.endpoint p:first-of-type,
section.endpoint p:first-of-type {
  background-color: var(--box-background) !important;
  text-overflow: ellipsis;
  overflow: hidden;
}
ul.endpoint,
section.endpoint {
  background-color: var(--background) !important;
}
ul.endpoint select,
section.endpoint select {
  background-color: var(--background);
  color: var(--font-color);
}
.api-method-example .itemizedlist {
  padding: 0;
}
div.api-method-example,
section.api-method-example {
  display: none !important;
}
.api-method-example.active {
  display: block !important;
}
#api-select {
  margin: 5px;
  width: calc(100% - 10px);
  border: none;
}
.api-method-example ul.itemizedlist li:first-of-type {
  display: none;
}
.api-method-example .informalexample .paligocode-wrapper {
  overflow: auto;
  margin-bottom: 7px;
  scrollbar-width: thin;
  position: inherit !important;
}
.api-method-example
.informalexample
.paligocode-wrapper::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 5px;
}
.api-method-example .example-request .paligocode-wrapper {
  max-height: calc(100vh / 4);
}
.api-method-example .example-response .paligocode-wrapper {
  max-height: calc(100vh / 3);
}
.api-method-example .example-webhook .paligocode-wrapper {
  max-height: calc(100vh / 2);
}
.api-method-example ::-webkit-scrollbar {
  height: 7px;
  width: 7px;
  background: var(--box-background);
}
.api-method-example ::-webkit-scrollbar-thumb {
  background: var(--box-background);
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px var(--box-background);
}
.api-method-example ::-webkit-scrollbar-corner {
  background: var(--box-background);
}
.api-method-example .code-block button[style] {
  top: 8px !important;
  right: 5px !important;
}
.api-method-example .example-request::before,
.api-method-example .example-response::before,
.api-method-example .example-webhook::before {
  border-bottom: 1px solid var(--border-color);
  padding: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background-color: var(--background);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: block;
}
.api-method-example .example-request::before {
  content: 'Request';
}
.api-method-example .example-response::before {
  content: 'Response';
}
.api-method-example .example-webhook::before {
  content: attr(data-content);
}
code.literal {
  background-color: var(--literal-background);
  padding: 3px 4px;
  border-radius: 4px;
  color: var(--placeholder);
  font-size: var(--font-size);
}
.inlinemediaobject{
  vertical-align: unset;
}
