.footer-content {
  display: none;
}
table.footer-container,
.footer-container table,
.footer-container {
  background-color: var(--footer-color) !important;
  width: 100%;
  margin-top: 15px !important;
}
div.footer-container {
  width: 100% !important;
  position: absolute !important;
  bottom: 0 !important;
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  overflow: hidden;
  height: var(--footer-height);
}
.footer-container tr td {
  padding: 0;
  border: none;
  vertical-align: top;
}

.footer-container .itemizedlist {
  line-height: 25px;
  margin: 0;
}
.footer-container .itemizedlist li {
  list-style: none;
}
.subscription-form {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}
.subscription-form input {
  border-radius: 8px;
}
.subscription-form input:focus {
  border-color: var(--color-primary);
}
#bottom-pager,
.site-footer {
  display: none;
}
.page-footer {
  position: sticky;
  min-height: var(--footer-height);
  overflow: hidden;
  width: 100%;
  margin-left: 0;
}
.hs-form-iframe {
  height: 110px !important;
}

@media (min-width: 768px) {
  .page-footer {
    width: 75%;
    margin-left: 25%;
  }
}

@media (min-width: 1200px) {
  .footer-container table tbody tr:first-child td:first-child {
    padding-left: 60px;
  }
  .page-footer {
    width: calc(100% - var(--sidebar-max-width));
    margin-left: var(--sidebar-max-width);
  }
}

@media (min-width: 2000px) {
  .informaltable.footer-container {
    padding: 0 60px !important;
  }
  .footer-container table {
    width: 75%;
    margin: 0 auto;
  }
}

@media (min-width: 576px) {
  .footer-container tr td:nth-child(2) {
    padding-top: 50px;
  }
  .hs-form-iframe {
    height: 200px !important;
  }
}

@media (max-width: 576px) {
  .subscription-form {
    flex-direction: column;
  }
  .footer-container tr {
    display: flex;
    flex-direction: column;
  }
  .footer-container table tbody tr:first-child td:first-child {
    padding-left: 0;
  }
  div.footer-container {
    min-height: var(--mobile-footer-height);
  }
  .page-footer {
    min-height: 275px;
  }
  .footer-container tr td:first-child {
    padding: 15px 15px 0 15px;
  }
  .footer-container tr td:nth-child(2) {
    padding: 0 15px 15px 15px;
  }
}

@media (max-width: 767px) {
  div.footer-container {
    bottom: auto !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  div.footer-container {
    bottom: 0 !important;
  }
  .page-footer, .footer-container {
    min-height: 280px;
  }
}

@media screen and (max-width: 389px) {
  div.footer-container {
    font-size: var(--font-size-xs);
  }
}

