#onetrust-consent-sdk .ot-pc-logo {
  display: none !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk {
  background-color: var(--aside-background-color) !important;
  outline: none !important;
  top: auto;
  bottom: 20px;
  right: 20px;
  left: auto;
}

#onetrust-consent-sdk #onetrust-pc-sdk {
  background-color: var(--background) !important;
}

#onetrust-consent-sdk #ot-pc-title,
#onetrust-consent-sdk #ot-pvcy-txt,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pvcy-hdr,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-grp-desc,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-policy-text,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-policy-title {
  color: var(--font-color) !important;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-grp-desc a,
#onetrust-consent-sdk #onetrust-pc-btn-handler {
  color: var(--link-color) !important;
}

#onetrust-consent-sdk #onetrust-pc-btn-handler {
  color: var(--link-color) !important;
  outline: none !important;
  background-color: transparent !important;
}

#onetrust-consent-sdk .ot-title-cntr {
  width: calc(100% - 20px) !important;
  padding-top: 15px !important;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-active-menu {
  border-color: var(--color-primary) !important;
  outline: none !important;
  background-color: var(--background) !important;
  color: var(--font-color) !important;
}

#onetrust-consent-sdk .save-preference-btn-handler,
#onetrust-consent-sdk #onetrust-accept-btn-handler{
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
  outline: none !important;
}

#onetrust-consent-sdk .ot-pc-footer-logo {
  display: none;
}

#onetrust-pc-sdk .ot-pc-header,
#onetrust-pc-sdk .ot-pc-footer {
  border-color: var(--border-color) !important;
}