* {
  box-sizing: border-box;
}

ul, h1, h2, h3, h4, h5, h6, table, thead, tbody, tr {
  margin: 0;
  padding: 0;
}

/*li {
  list-style: none;
}*/

main ul {
  margin: 0;
}

a {
  text-decoration: none;
}

a:focus {
  outline: none;
}

img {
  max-width: 100%;
}

button {
  border: none;
  cursor: pointer;
}

button:focus {
  outline: none;
}

.text-center {
  text-align: center;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
}

input:focus {
  outline: none;
}

.half-width {
  width: 50%;
}
