.mb-5 {
  margin-bottom: 2.5em;
}
.v-none {
  visibility: hidden;
}
.mt-m-30 {
  margin-top: -30px !important;
}
.no-border {
  border: none !important;
}

