input#search-bar , input#side-search-bar{
  width: 100%;
  border: 1px solid var(--border-color);
  color: var(--font-color);
  background-color: var(--nav-item-hover-background);
  height: 34px;
  border-radius: 4px;
  padding-left: 40px;
}

.site-header-search .algolia-autocomplete {
  width: 100%;
}

.search-input-container{
  position: relative;
}

.search-result-breadcrumbs.recent{
  font-size: 12px;
  color: var(--gray-text);
}

.search-result-data{
  display: flex;
  padding: 5px 5px 5px 15px;
}

.search-result-data.all{
  display: flex;
  flex-direction: column;
  padding: 15px;
  row-gap: 5px;
  white-space: initial;
}

.search-result-data.recent , .search-result-data.popular {
  padding-left: 15px;
  margin: 13px;
}

.search-result-container.recent{
  margin: 0;
}

.search-results-separating-line.recent{
  width:95%
}

.search-result-breadcrumbs.recent .breadcrumb-text.mark{
  color: var(--gray-text);
}

.search-input-container .icon-search::before{
  position: absolute;
  left: 8px;
  top: 8px;
}

.site-sidebar-search .icon-search::before {
   position: absolute;
   left: 21px;
   top: 29px;
   z-index: 1;
}

.site-sidebar-search .icon-delete{
  position: absolute;
  right: 20px;
  top: 25px;
}

.search-input-container .icon-delete::before{
  position: absolute;
  right: 8px;
  top: 8px;
}

.search-dropdown {
  display: none;
  position: absolute;
  background-color: var(--dropdown-background);
  border: 1px solid var(--border-color);
  overflow-y: auto;
  width: 100%;
  padding-top: 10px;
  max-height: 600px;
  margin-top: 10px;
  border-radius: 4px;
}

.sidebar-search-dropdown {
  display: none;
  position: absolute;
  background-color: var(--dropdown-background);
  width: 100%;
  margin-top: 70px;
  z-index: 1;
}

.all-results-modal {
  display: none;
  background-color: var(--dropdown-background);
  border: 1px solid var(--border-color);
  overflow-y: auto;
  width: 100%;
  padding-top: 10px;
  height: calc(100% - 63px);
  margin-top: 64px;
  position: fixed;
  justify-content: center;
  bottom: 0;
  flex-direction: column;
}

.all-results-container{
  width: 60%;
  background-color: var(--dropdown-background);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 85%;
}

.exit-all-result{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 20px 0 0;
}

.site-header-all-results{
  height: 100%;
}

.show-all-results-container{
  margin: 0 auto;
  width: 100%;
  border-top: 1px solid var(--all-results-border);
  display: flex;
  justify-content: center;
  padding: 10px 0;
  cursor: pointer;
  position: sticky;
  bottom: 0;
  background-color: var(--dropdown-background);
}

.show-all-results{
  color: var(--mark-results);
  font-size: 13px;
}

mark{
  background-color: var(--mark-results);
  margin: 0;
  padding: 0;
}

form input#search-bar::placeholder , form input#side-search-bar::placeholder {
  color: var(--placeholder);
}

form input#search-bar:focus, form input#side-search-bar:focus {
  border-color: var(--color-primary);
}

.searchresults {
  margin-top: 15px;
}

.search-result-title{
  font-family: var(--font-family);
  font-weight: 500;
  color: var(--font-color);
  font-size: 14px;
}

.search-result-title .result-mark{
  background-color: var(--mark-results);
  margin: 0;
  padding: 0;
}

.search-result-title.all{
  font-size: 24px;
  font-weight: 700;
}

.search-result-body{
  color: var(--gray-text);
}

.search-result-body.all{
  line-height: 16px;
  font-size: 14px;
}

.search-result-breadcrumbs.all{
  line-height: 16px;
  font-size: 14px;
  margin-bottom: 5px;
}

.search-results-headline{
  font-family: var(--font-family);
  font-weight: 500;
  color: var(--gray-text);
  font-size: 12px;
  margin-left: 15px;
}

.search-result-container{
  background-color: var(--dropdown-background);
  overflow-x: visible;
  margin: 15px;
  border-radius: 4px;
}

.search-result-container.all{
  border-radius: 4px;
  margin: 0;
}

.result-separator{
  background-color: var(--all-results-border);
  height: 1px;
  width: 100%;
}

.search-result-container:hover{
  background-color: var(--result-hover-background);
}

.category{
  font-family: var(--font-family);
  margin:5px 0 0 20px;
  list-style-type:none;
}

.all-results-category-container{
  width: 100%;
  background-color: var(--dropdown-background);
  color: var(--font-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--all-results-border);
  cursor: pointer;
  padding-bottom: 10px;
}

.all-results-category-container:hover{
  background-color: var(--hover-background-color);
}

.all-results-category-container:hover .category-length {
  background-color: var(--hover-background-color-icon);
  color: var(--btn-text-color);
}

.all-results-category-container.active{
  border-bottom: 2px solid var(--color-primary);
  color: var(--color-primary);
  font-weight: 500;
}

.all-results-category-container.active .category-length {
  background-color: var(--color-primary);
  color: var(--btn-text-color);
}

.all-results-category-container.disabled{
  cursor: not-allowed;
  pointer-events: none;
  opacity: 50%;
}

.all-results-category-container.disabled .category-length{
  background-color: var(--hover-background-color-icon);
  color: var(--btn-text-color);
}

.selected-searchresultitem {
  background-color: var(--box-background) !important;
}

.selected-searchresultitem .searchresulttitle,
.selected-searchresultitem .search-result-breadcrumbs {
  color: var(--font-color) !important;
}

.searchresultitem {
  border-color: var(--jumbotron-border-color) !important;
}

.search-highlight {
  font-style: normal !important;
}

.category-container{
  background-color: var(--category-background-color);
  display: flex;
  color: var(--gray-text);
}

.all-results-category{
  display: flex;
  height: 40px;
  margin-bottom: 10px;
  white-space: nowrap;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
}

.all-results-category::-webkit-scrollbar{
  display: none;
}

.all-result-header{
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pagination-container{
  margin-top: auto;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--dropdown-background);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: self-start;
  height: 8%;
  padding-top: 10px;
}

.pagination-button{
  background-color: var(--dropdown-background);
}


.pagination-number{
  font-size: 14px;
  margin: 0 5px;
  background-color: var(--dropdown-background);
  width: 34px;
}

.pagination-number.active{
  border: 1px solid var(--pagination-border-color);
  color: var(--color-primary);
  border-radius: 25%;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.05), 0 1px 6px 0 rgba(0, 0, 0, 0.05);
}

.pagination-number:hover{
  color: var(--color-primary);
}

.pagination-button-next.disabled, .pagination-button-prev.disabled{
  cursor: not-allowed;
  pointer-events: none;
  color:var(--disabled-color);
}

.pagination-button-next, .pagination-button-prev{
  color: var(--color-primary);
  background-color: var(--dropdown-background);
}

.pagination-button-next span::before, .pagination-button-prev span::before{
  font-size: var(--icon-size-lg);
}


.category-length{
  background-color: var(--result-hover-background);
  color: var(--font-color);
  padding: 4px 8px;
  border-radius: 100px;
  margin-left: 15px;
  min-width: 30px;
  max-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-results-all{
  overflow: auto;
}

.all-results-headline{
  font-weight: 700;
  font-size: 34px;
  margin: 30px;
}

.all-results-title-container{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.search-result-icon.all{
  margin: 10px 10px 10px 0;
  color: var(--font-color);
}

.search-result-icon{
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.all-results-title-container .search-result-icon::before, .search-result-data .search-result-icon::before{
  font-size: var(--icon-size-md);
  color: var(--gray-text);
}

.search-result-breadcrumbs{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: var(--gray-text);
}

.breadcrumb-text.mark{
  color: var(--color-primary);
  background-color: inherit;
  margin-bottom: 2px;
}

.search-results-empty{
  content: var(--empty-state);
}

.search-results-empty-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px;
}

.search-results-empty-container span{
  color: var(--gray-text);
  text-align: center;
}

.pagination-items{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.exit-all-result .exit-results-modal::before{
  font-size: var(--icon-size-md);
  color: var(--gray-text);
}

.sidebar-search-dropdown .search-result-breadcrumbs{
  font-size: 12px;
}

@media (max-width: 980px) {
  .all-results-category-container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 150px;
  }

  .all-results-container {
    width: 100%;
    height: 85%;
  }
  .search-result-title.all{
    font-size: 16px;
  }

  .search-result-breadcrumbs.all{
    font-size: 14px;
  }

  .all-results-headline{
    margin: 0 0 30px 15px;
  }

  .all-results-category{
    display: flex;
    overflow: auto;
  }


  .all-results-container {
    white-space: nowrap;
  }

  .all-results-category {
    overflow-x: auto;
    display: flex;
    height: 50%;
  }

  .all-results-category-container:hover{
    background-color: initial;
  }

  .all-results-category-container:hover .category-length:hover {
    background-color: var(--result-hover-background);
    color: initial;
  }

}