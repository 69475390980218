
.payment-table-container table {
    width: 100%;
    font-family: Arial, sans-serif;
    background: var(--background);
    color: var(--font-color);
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}


.payment-table-container table {
    border-spacing: 0;
    border-collapse: separate;
}

.payment-table-container th,
.payment-table-container td {
    border: 1px solid var(--border-color);
}

#payments-table > thead {
    position: sticky;
    top: 0;
    z-index: 90;
    background-color: var(--background);
}


.payment-table-container th,
.payment-table-container td {
    padding: 15px;
    position: relative;
}

.payment-table-container th {
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--gray-text);
}

.payment-table-container tr td:first-child {
    font-weight: 600;
}

.payment-table-container table thead {
    box-shadow: rgb(233, 233, 233) 0px -1px 0px inset;
    background-color: var(--background);
}

.preloader-container-table {
    position: absolute;
    width: 100%;
    height: 500px;
    z-index: 1;
    overflow: hidden;
    align-items: center;
    margin-top: 30px;
}

.hidden {
    display: none;
}

.show {
    display: block;
}


.loader-table {
    background-image: url('../assets/images/Ripple.svg');
    background-repeat: no-repeat;
    margin: 113px auto;
    margin-top: 176px;
    top: 38px;
    width: 200px;
    height: 400px;
}

.loader-table:before, .loader-table:after {
    content: '';
    position: absolute;
}

.payment-table-container th::after, .payment-table-container td::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.payment-table-container th::before, .payment-table-container td::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}

.payment-table-container .show-more-button {
    cursor: pointer;
    color: var(--icon-color);
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    margin-top: 8px;
}

.payment-table-container tr {
    left: 0px;
    width: 100%;
    height: 55px;
}

#payments-table > tr:first-child {
    position: sticky;
    top: 0;
    z-index: 90;
    background-color: var(--background);
}

.payment-table-container .country-container {
    display: block;
    margin-bottom: 10px;
}

.payment-table-container th {
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--gray-text);
}

.payment-table-container table thead {
    box-shadow: rgb(233, 233, 233) 0px -1px 0px inset;
    background-color: var(--background);
}

.payment-table-container tbody {
    height: auto;
    overflow: auto;
}

.payment-table-container td {
    color: var(--gray-text);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    box-sizing: border-box;
    width: 150px;
}

.payment-table-container .dropbtn {
    background: url('../assets/images/filter-Icon.svg') no-repeat center;
    color: white;
    border: none;
    padding: 8px 10px;
    vertical-align: middle;
    cursor: pointer;
    float: right;
}

.payment-table-container td div {
    word-break: break-all;
}

.payment-table-container .features-container div {
    width: max-content;
}

.payment-table-container .features-container {
    width: 100%;
}

.check_text .features-text {
    color: var(--gray-text)
}

.payment-table-container .check {
    background: url('../assets/images/IconsV.svg') no-repeat;
    height: 20px;
    width: 20px;
    float: left;
    padding: 10px;
    align-items: self-start;
    margin-right: 5 pxf
}

.payment-table-container .flag-icon {
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: var(--flag-url, none);
    display: inline-block;
    margin-right: 8px;
    vertical-align: bottom;
}

.payment-table-container .delete {
    background: url('../assets/images/IconsX.svg') no-repeat;
    height: 20px;
    width: 20px;
    float: left;
    padding: 10px;
    align-items: self-start;
    color: #676B73
}

.flag-text {
    width: 70%;
}

.payment-table-container .contentContainer {
    display: block;
}

.delete_text {
    color: #a4a6aa;
    display: block;
    margin-bottom: 5px;
}

.payment-table-container tr th span {
    font-weight: bold;
}

.payment-table-container .list-container {
    padding: 10px;
    min-height: 70%;
}

.payment-table-container .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--background);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    height: 20vh;
    overflow: auto;
    border: 1px solid var(--border-color);
    z-index: 1;
    color: var(--gray-text);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: capitalize;
    border-radius: 6px;
    width: 300px;
    word-break: break-all;
    overflow-y: auto;

}

.payment-table-container .custom-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 18px;
    cursor: pointer;
    color: var(--gray-text);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: capitalize;
}

.payment-table-container .custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.payment-table-container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 3px;
    border: 1.5px solid var(--border-color);
    background: var(--background);
}


.payment-table-container .custom-checkbox input:checked ~ .checkmark {
    opacity: 1;
    background-color: var(--icon-color);
    content: "";
    background-image: url('../assets/images/vIcon.svg');
    background-repeat: no-repeat;
    background-position: center;
    color: var(--font-color);
}

.payment-table-container #search-dropdown {
    height: 34px;
    padding-left: 26px;
    background: url('../assets/images/searchIcon.svg') 6px 9px no-repeat;
    border-radius: 4px;
    outline: 0;
    margin: 10px 10px 12px;
    border-radius: 4px;
    width: 93%;
    border: 1px solid var(--border-color);
    display: block;
}

.payment-table-container #input_container {
    position: relative;
    padding: 0;
    margin: 0;
}


.payment-table-container .dropdown-content {
    display: block;
}

.payment-table-container .dropdown-content {
    display: none;
}

.features-dropdown-content-container {
    display: none;
}

.bth-click {
    background: var(--icon-filtered);
    color: white;
    border: none;
    padding: 8px 10px;
    vertical-align: middle;
    cursor: pointer;
    float: right;
}

#content-wrapper {
    height: 100%;
}

.dropbtn {
    cursor: pointer;
}

.flag-text {
    display: inline-block;
}

.check_text {
    color: #a4a6aa;
    display: block;
    margin-bottom: 5px;
}

.reset-btn-container {
    position: sticky;
    bottom: 0;
    display: flex;
    z-index: 90;
    width: 100%;
    color: var(--icon-color);
    text-align: center;
    height: 36px;
    border-radius: 8px;
    background-color: var(--background);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-top: 1px solid var(--border-color);
    font-family: Inter;
    cursor: pointer;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.reset-btn {
    background: transparent;
}

.reset-btn-icon {
    background: var(--icon-reset);
    color: var(--icon-color);
    border: none;
    margin-top: 10px;
    margin-left: 20px;
    width: 16px;
    height: 16px;
}

.payment-table-container .dropdown-content input[type="checkbox"][value="all"] {
    font-weight: bold;
}

.payment-table-container .informaltable.table-responsive {
    overflow: auto;
    width: 100%;
    height: 47vh;
    overflow-x: auto;
}

.payment-table-container table thead tr th {
    text-align: left;
    white-space: nowrap;
}



