td:has(.preloader-container) {
  position: relative;
}

.preloader-container {
  position: absolute;
  background-color: var(--footer-color);
  width: 100%;
  height: 100%;
  z-index: 1;
  visibility: hidden;
  overflow: hidden;
  display: flex !important;
  align-items: center;
  margin-top: 30px;
}

.preloader-container.show {
  visibility: visible;
}

.loader, .loader:before, .loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: spinner-keyframe 1.8s infinite ease-in-out;
}

.loader {
  color: var(--font-color);
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  animation-delay: -0.16s;
  top: -4.0em;
}

.loader:before, .loader:after {
  content: '';
  position: absolute;
}

.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@keyframes spinner-keyframe {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
