.site-header .theme-switcher {
  position: absolute;
  top: 15px;
  right: 160px;
  border: 1px solid var(--border-color);
  width: 74px;
  height: 36px;
  border-radius: 8px;
  background-color: var(--box-background);
  font-family: fontello;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

html[data-theme='dark'] .site-header .theme-switcher {
  background-color: var(--background);
}

html[data-theme='dark'] .site-header .theme-switcher .slider {
  transform: translate(-35px);
}

.site-header .theme-switcher .slider {
  position: absolute;
  background-color: var(--color-primary);
  width: 30px;
  height: 28px;
  right: 3px;
  transition: all ease-out .2s;
  border-radius: 5px;
}

.site-header .theme-switcher:before {
  content: '\f186';
  font-size: 16px;
  margin-left: 7px;
  color: var(--icon-font-color);
  z-index: 1;
}

html[data-theme='dark'] .site-header .theme-switcher:before {
  color: var(--btn-text-color);
}

.site-header .theme-switcher:after {
  content: '\e80c';
  font-size: 19px;
  margin-right: 3px;
  z-index: 1;
  color: var(--btn-text-color);
}

html[data-theme='dark'] .site-header .theme-switcher:after {
  color: var(--icon-font-color);
}

@media (min-width: 768px) {
  .site-header .theme-switcher {
    right: 130px;
  }
}

@media (max-width: 389px) {
  .site-header .theme-switcher {
    right: 130px;
  }
}