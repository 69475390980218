ul.card-container,
section.card-container {
  display: flex;
  padding: 10px;
  overflow-y: auto;
}
.card-container .titlepage {
  display: none;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 30px;
  background: var(--card-background);
  box-shadow: var(--card-shadow);
  border-radius: 12px;
  flex: 1 1 0;
}
.card.link {
  cursor: pointer;
  color: inherit !important;
}
.card .titlepage {
  display: none;
}
.card ul {
  padding: 0;
}
.card ul li {
  list-style: none;
}
.card a {
  color: var(--font-color);
}
.blue-text a,
.blue-text {
  color: var(--color-primary);
}
.card .card-title {
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.card .card-body {
  font-weight: 400;
  font-size: var(--font-size);
  line-height: 25px;
  margin-bottom: 5px;
}
.card .card-footer {
  margin-top: auto;
}
.card .icon-container {
  width: 40px;
  height: 40px;
  background: var(--icon-background-color);
  overflow: hidden;
  border-radius: 50px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-container .icon {
  font-size: 25px;
}
.card-container .card {
  margin: 0 15px;
}
.card-container .card:first-of-type {
  margin: 0 15px 0 0 !important;
}
.card-container .card:last-child {
  margin: 0 0 0 15px !important;
}
div.card-container {
  padding: 0 !important;
  margin: 0 !important;
}
.card-link {
  display: none;
}
.card .icon {
  color: var(--color-branding-secondary) !important;
}
/* Extra small devices*/
@media (max-width: 767px) {
  .card-container {
    flex-direction: column;
  }
  .card-container .card,
  .card-container .card:first-of-type,
  .card-container .card:last-child {
    margin: 15px 0 !important;
  }
}
