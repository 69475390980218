.accordion:not(:last-child) .panel {
    border-bottom: 1px solid var(--jumbotron-border-color);
}

.accordion:last-child .panel {
    border-bottom: none;
}

.accordion ul {
    margin-bottom: 10px !important;
}

.accordion p {
    margin-bottom: 1em;
}

.accordion p[class*='datatype-'] {
    margin-bottom: 0;
}

.accordion .panel-heading {
    padding: 0;
    position: relative;
}

.accordion .panel {
    background-color: var(--background);
}

.accordion .panel-heading .title {
    color: var(--notification-font-color);
    font-size: 20px;
    margin: 20px 0 40px 0 !important;
    font-family: var(--font-family);
}

.accordion .panel-heading.active .title {
    margin-bottom: 24px !important;
}

.accordion .panel-heading .title > .title:before {
    position: absolute;
    right: 3px;
    margin-right: 0 !important;
    content: '\e875';
    font-family: fontello;
}

.accordion .panel-heading.active .title > .title:before {
    content: '\e874';
}

.accordion .panel-body {
    padding: 0;
    margin: 0 0 40px 0;
}

h3.title.icon-1, h4.title.icon-1, h5.title.icon-1, h6.title.icon-1 {
    padding-left: 36px;
    background: var(--icon-number-1);
    height: 30px;
    background-size: contain;
    padding-top: 2px;
}

h3.title.icon-2, h4.title.icon-2, h5.title.icon-2, h5.title.icon-2, h6.title.icon-2 {
    padding-left: 36px;
    background: var(--icon-number-2);
    height: 30px;
    background-size: contain;
    padding-top: 2px;
}

h3.title.icon-3, h4.title.icon-3, h5.title.icon-3, h6.title.icon-3 {
    padding-left: 36px;
    background: var(--icon-number-3);
    height: 30px;
    background-size: contain;
    padding-top: 2px;
}

h3.title.icon-4, h4.title.icon-4, h5.title.icon-4, h6.title.icon-4 {
    padding-left: 36px;
    background: var(--icon-number-4);
    height: 30px;
    background-size: contain;
    padding-top: 2px;
}

h3.title.icon-5, h4.title.icon-5, h5.title.icon-5, h6.title.icon-5 {
    padding-left: 36px;
    background: var(--icon-number-5);
    height: 30px;
    background-size: contain;
    padding-top: 2px;
}

h3.title.icon-6, h4.title.icon-6, h5.title.icon-6, h6.title.icon-6 {
    padding-left: 36px;
    background: var(--icon-number-6);
    height: 30px;
    background-size: contain;
    padding-top: 2px;
}

h3.title.icon-7, h4.title.icon-7, h5.title.icon-7, h6.title.icon-7 {
    padding-left: 36px;
    background: var(--icon-number-7);
    height: 30px;
    background-size: contain;
    padding-top: 2px;
}

h3.title.icon-8, h4.title.icon-8, h5.title.icon-8, h6.title.icon-8 {
    padding-left: 36px;
    background: var(--icon-number-8);
    height: 30px;
    background-size: contain;
    padding-top: 2px;
}

h3.title.icon-9, h4.title.icon-9, h5.title.icon-9, h6.title.icon-9 {
    padding-left: 36px;
    background: var(--icon-number-9);
    height: 30px;
    background-size: contain;
    padding-top: 2px;
}

h3.title.icon-10, h4.title.icon-10, h5.title.icon-10, h6.title.icon-10 {
    padding-left: 36px;
    background: var(--icon-number-10);
    height: 30px;
    background-size: contain;
    padding-top: 2px;
}

/* TODO chek if everything works fine without this style */
/*.accordion .panel-body :last-child {*/
/*  margin-bottom: 0;*/
/*}*/