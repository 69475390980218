.blue-text {
  color: var(--color-primary);
}
.strong {
  font-weight: 600 !important;
}
.titlepage .title {
  margin-top: 30px !important;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px !important;
  color: var(--font-color);
  font-family: var(--font-family);
  text-transform: none;
}
.titlepage .subtitle {
  font-weight: 500;
  font-size: 16px;
}
.body-text {
  font-size: 16px;
  line-height: 25px;
}
a:not(.btn),
a:not(.btn):hover,
a:not(.btn):focus {
  color: var(--color-primary);
  text-decoration: none;
}
.topic-content section > p {
  margin-bottom: 15px;
}
.topic-content > section > .titlepage:first-child .title {
  font-weight: 700;
  font-size: 34px;
  line-height: 41px;
  margin-bottom: 20px !important;
  margin-top: 0 !important;
}
.topic-content > section > section > .titlepage:first-child .title {
  font-size: 28px;
}
.topic-content > section > section > section > .titlepage:first-child .title {
  font-size: 20px;
}
p.section-title {
  margin-top: 10px !important;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px !important;
  color: var(--font-color);
}
/* Overrides for the dark theme only */
html[data-theme='dark'] .hljs-keyword,
html[data-theme='dark'] .hljs-built_in {
  color: var(--code-literal-color);
}
html[data-theme='dark'] .hljs-title {
  color: var(--code-string-color);
}
html[data-theme='dark'] .hljs-comment {
  color: var(--danger-color);
}
h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4,
h5,
.h5 {
  font-family: var(--font-family);
  color: var(--font-color);
  text-transform: none;
}
h1,
.h1 {
  font-weight: 700;
  font-size: 34px;
}
h2,
.h2 {
  font-weight: var(--font-weight-bold);
  font-size: 26px;
}
h3,
.h3 {
  font-weight: var(--font-weight-bold);
  font-size: 24px;
}
h4,
.h4 {
  font-weight: var(--font-weight-bold);
  font-size: 20px;
}
