.site-sidebar {
  top: var(--header-height);
  background-color: var(--aside-background-color);
  border-right: var(--aside-border);
  box-shadow: var(--aside-box-shadow);
  overflow: auto;
}

.site-sidebar.full{
  width: 100%;
}

.site-sidebar > ul > li > a {
  text-transform: uppercase;
  border-bottom: 1px solid var(--jumbotron-border-color);
  border-radius: 0;
}

.site-sidebar .logo {
  display: none !important;
}

.collapsible-sidebar-nav .site-sidebar-header .navbar-toggle {
  display: none !important;
}

.site-sidebar-search {
  margin: 20px 10px 0;
  display: flex;
}

.toc .glyphicon:before {
  content: '\e259';
}

.toc .opened > .topic-link > .glyphicon:before {
  content: '\e260';
}

@media (min-width: 1200px) {
  .site-content {
    width: calc(100% - var(--sidebar-max-width));
  }

  .site-sidebar {
    width: var(--sidebar-max-width);
  }
}

@media (min-width: 768px) {
  .site-sidebar-search {
    display: none;
  }
  .sidebar-search-dropdown {
    display: none;
  }
}

@media (max-width: 576px) {
  .site-sidebar {
    width: 100%;
  }
}
