@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?413354');
  src: url('../font/fontello.eot?413354#iefix') format('embedded-opentype'),
    url('../font/fontello.woff2?413354') format('woff2'),
    url('../font/fontello.woff?413354') format('woff'),
    url('../font/fontello.ttf?413354') format('truetype'),
    url('../font/fontello.svg?413354#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?413354#fontello') format('svg');
  }
}
*/
[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes */
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Base size */
  font-size: var(--icon-size-xs);

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
[class^='icon-'].sm:before,
[class*=' icon-'].sm:before {
  font-size: var(--icon-size-sm);
}
[class^='icon-'].md:before,
[class*=' icon-'].md:before {
  font-size: var(--icon-size-md);
}
[class^='icon-'].lg:before,
[class*=' icon-'].lg:before {
  font-size: var(--icon-size-lg);
}

.icon-approve_balance:before {
  content: '\e800';
} /* '' */
.icon-certification:before {
  content: '\e801';
} /* '' */
.icon-nest:before {
  content: '\e802';
} /* '' */
.icon-reload_balance:before {
  content: '\e803';
} /* '' */
.icon-stepper:before {
  content: '\e804';
} /* '' */
.icon-more_vertical:before {
  content: '\e805';
} /* '' */
.icon-more_horizontal:before {
  content: '\e806';
} /* '' */
.icon-drag:before {
  content: '\e807';
} /* '' */
.icon-dollar:before {
  content: '\e808';
} /* '' */
.icon-resend:before {
  content: '\e809';
} /* '' */
.icon-app:before {
  content: '\e80a';
} /* '' */
.icon-user_minus:before {
  content: '\e80b';
} /* '' */
.icon-layers:before {
  content: '\e80c';
} /* '' */
.icon-folder-1:before {
  content: '\e80d';
} /* '' */
.icon-inbox:before {
  content: '\e80e';
} /* '' */
.icon-like:before {
  content: '\e80f';
} /* '' */
.icon-dislike:before {
  content: '\e810';
} /* '' */
.icon-close_2:before {
  content: '\e811';
} /* '' */
.icon-plus_2:before {
  content: '\e812';
} /* '' */
.icon-doc:before {
  content: '\e813';
} /* '' */
.icon-search-doc:before {
  content: '\e813';
  font-size: 20px;
  margin: 10px 10px 0 0;
}
.icon-search-info_sq:before {
  content: '\e814';
  font-size: 20px;
  margin: 10px 10px 0 0;
}
.icon-search-refresh:before {
  content: '\e816';
  font-size: 20px;
  margin: 10px 10px 0 0;
}
.icon-search-icon-chevron_right:before {
  content: '\e818';
  font-size: 20px;
  margin: 10px 10px 0 0;
} /* '' */
.icon-info_sq:before {
  content: '\e814';
} /* '' */
.icon-refund:before {
  content: '\e815';
} /* '' */
.icon-refresh:before {
  content: '\e816';
} /* '' */
.icon-transactions:before {
  content: '\e817';
} /* '' */
.icon-chevron_right:before {
  content: '\e818';
} /* '' */
.icon-calculator-2:before {
  content: '\e819';
} /* '' */
.icon-chevron_left:before {
  content: '\e81a';
} /* '' */
.icon-precntage:before {
  content: '\e81b';
} /* '' */
.icon-hashtag:before {
  content: '\e81c';
} /* '' */
.icon-check:before {
  content: '\e81d';
} /* '' */
.icon-geteway:before {
  content: '\e81e';
} /* '' */
.icon-users:before {
  content: '\e81f';
} /* '' */
.icon-download:before {
  content: '\e820';
} /* '' */
.icon-bars:before {
  content: '\e821';
} /* '' */
.icon-merchant:before {
  content: '\e822';
} /* '' */
.icon-deactivate:before {
  content: '\e823';
} /* '' */
.icon-activate:before {
  content: '\e824';
} /* '' */
.icon-verify_identity:before {
  content: '\e825';
} /* '' */
.icon-simulate:before {
  content: '\e826';
} /* '' */
.icon-pin:before {
  content: '\e827';
} /* '' */
.icon-label:before {
  content: '\e828';
} /* '' */
.icon-download_transactions:before {
  content: '\e829';
} /* '' */
.icon-dispute:before {
  content: '\e82a';
} /* '' */
.icon-add_payout:before {
  content: '\e82b';
} /* '' */
.icon-add_iban:before {
  content: '\e82c';
} /* '' */
.icon-accept:before {
  content: '\e82d';
} /* '' */
.icon-gender:before {
  content: '\e82e';
} /* '' */
.icon-fingerprint:before {
  content: '\e82f';
} /* '' */
.icon-developers:before {
  content: '\e830';
} /* '' */
.icon-reserved-balance:before {
  content: '\e831';
} /* '' */
.icon-pending-balance:before {
  content: '\e832';
} /* '' */
.icon-available_balance:before {
  content: '\e833';
} /* '' */
.icon-cash-out:before {
  content: '\e834';
} /* '' */
.icon-cash-in:before {
  content: '\e835';
} /* '' */
.icon-notification-1:before {
  content: '\e836';
} /* '' */
.icon-activate-card:before {
  content: '\e837';
} /* '' */
.icon-dollar-transfer:before {
  content: '\e838';
} /* '' */
.icon-collect:before {
  content: '\e839';
} /* '' */
.icon-disburse:before {
  content: '\e83a';
} /* '' */
.icon-world:before {
  content: '\e83b';
} /* '' */
.icon-bulk:before {
  content: '\e83c';
} /* '' */
.icon-comment:before {
  content: '\e83d';
} /* '' */
.icon-status:before {
  content: '\e83e';
} /* '' */
.icon-calendar-notification:before {
  content: '\e83f';
} /* '' */
.icon-filter:before {
  content: '\e840';
} /* '' */
.icon-graph:before {
  content: '\e841';
} /* '' */
.icon-timer:before {
  content: '\e842';
} /* '' */
.icon-block:before {
  content: '\e843';
} /* '' */
.icon-show:before {
  content: '\e844';
} /* '' */
.icon-shield-off:before {
  content: '\e845';
} /* '' */
.icon-info_2:before {
  content: '\e846';
} /* '' */
.icon-bookmark:before {
  content: '\e847';
} /* '' */
.icon-chart:before {
  content: '\e848';
} /* '' */
.icon-folder:before {
  content: '\e849';
} /* '' */
.icon-search:before {
  content: '\e84a';
} /* '' */
.icon-audit:before {
  content: '\e84b';
} /* '' */
.icon-favorite:before {
  content: '\e852';
} /* '' */
.icon-user:before {
  content: '\e859';
} /* '' */
.icon-minus:before {
  content: '\e85c';
} /* '' */
.icon-delete:before {
  content: '\e85e';
} /* '' */
.icon-plus:before {
  content: '\e85f';
} /* '' */
.icon-minus_sq:before {
  content: '\e862';
} /* '' */
.icon-check_sq:before {
  content: '\e863';
} /* '' */
.icon-delete_sq:before {
  content: '\e864';
} /* '' */
.icon-add_sq:before {
  content: '\e866';
} /* '' */
.icon-doc_upload:before {
  content: '\e867';
} /* '' */
.icon-doc_download:before {
  content: '\e868';
} /* '' */
.icon-doc_minus:before {
  content: '\e86a';
} /* '' */
.icon-doc_delete:before {
  content: '\e86b';
} /* '' */
.icon-doc_plus:before {
  content: '\e86c';
} /* '' */
.icon-chevron_c_left:before {
  content: '\e86e';
} /* '' */
.icon-chevron_c_right:before {
  content: '\e86f';
} /* '' */
.icon-chevron_c_up:before {
  content: '\e870';
} /* '' */
.icon-chevron_c_down:before {
  content: '\e871';
} /* '' */
.icon-chevron_up:before {
  content: '\e874';
} /* '' */
.icon-chevron_down:before {
  content: '\e875';
} /* '' */
.icon-chevron_s_left:before {
  content: '\e876';
} /* '' */
.icon-chevron_s_right:before {
  content: '\e877';
} /* '' */
.icon-chevron_s_up:before {
  content: '\e878';
} /* '' */
.icon-chevron_s_down:before {
  content: '\e879';
} /* '' */
.icon-arrow_s_down:before {
  content: '\e87a';
} /* '' */
.icon-arrow_s_up:before {
  content: '\e87b';
} /* '' */
.icon-arrow_s_left:before {
  content: '\e87c';
} /* '' */
.icon-arrow_s_right:before {
  content: '\e87d';
} /* '' */
.icon-arrow_down:before {
  content: '\e87e';
} /* '' */
.icon-arrow_up:before {
  content: '\e87f';
} /* '' */
.icon-arrow_left:before {
  content: '\e880';
} /* '' */
.icon-arrow_right:before {
  content: '\e881';
} /* '' */
.icon-save:before {
  content: '\e885';
} /* '' */
.icon-attach:before {
  content: '\e886';
} /* '' */
.icon-euro:before {
  content: '\e887';
} /* '' */
.icon-pound:before {
  content: '\e888';
} /* '' */
.icon-bitcoin:before {
  content: '\e889';
} /* '' */
.icon-warning:before {
  content: '\e88b';
} /* '' */
.icon-warning_2:before {
  content: '\e88c';
} /* '' */
.icon-calendar:before {
  content: '\e88d';
} /* '' */
.icon-doc_main:before {
  content: '\e88e';
} /* '' */
.icon-paypal:before {
  content: '\e88f';
} /* '' */
.icon-server-1:before {
  content: '\e892';
} /* '' */
.icon-copy:before {
  content: '\e895';
} /* '' */
.icon-focus:before {
  content: '\e896';
} /* '' */
.icon-wifi_on:before {
  content: '\e897';
} /* '' */
.icon-wifi_off:before {
  content: '\e898';
} /* '' */
.icon-speed:before {
  content: '\e899';
} /* '' */
.icon-heart:before {
  content: '\e89a';
} /* '' */
.icon-settings:before {
  content: '\e89b';
} /* '' */
.icon-login:before {
  content: '\e89c';
} /* '' */
.icon-board:before {
  content: '\e89e';
} /* '' */
.icon-share_2:before {
  content: '\e89f';
} /* '' */
.icon-loop:before {
  content: '\e8a0';
} /* '' */
.icon-building:before {
  content: '\e8a2';
} /* '' */
.icon-resize:before {
  content: '\e8a3';
} /* '' */
.icon-deploma:before {
  content: '\e8a4';
} /* '' */
.icon-crop:before {
  content: '\e8a6';
} /* '' */
.icon-shop:before {
  content: '\e8a7';
} /* '' */
.icon-plugin:before {
  content: '\e8a9';
} /* '' */
.icon-privacy:before {
  content: '\e8aa';
} /* '' */
.icon-bank_redirect:before {
  content: '\e8ab';
} /* '' */
.icon-business:before {
  content: '\e8ac';
} /* '' */
.icon-envelop:before {
  content: '\e8ad';
} /* '' */
.icon-link:before {
  content: '\e8ae';
} /* '' */
.icon-menu_back:before {
  content: '\e8b0';
} /* '' */
.icon-360:before {
  content: '\e8b1';
} /* '' */
.icon-image:before {
  content: '\e8b2';
} /* '' */
.icon-cart:before {
  content: '\e8b3';
} /* '' */
.icon-chart-1:before {
  content: '\e8b4';
} /* '' */
.icon-trash:before {
  content: '\e8b5';
} /* '' */
.icon-hide:before {
  content: '\e8b6';
} /* '' */
.icon-edit:before {
  content: '\e8b7';
} /* '' */
.icon-location:before {
  content: '\e8ba';
} /* '' */
.icon-upload:before {
  content: '\e8bc';
} /* '' */
.icon-adjusments:before {
  content: '\e8bd';
} /* '' */
.icon-lock:before {
  content: '\e8be';
} /* '' */
.icon-unlock:before {
  content: '\e8bf';
} /* '' */
.icon-keyboard:before {
  content: '\e8c0';
} /* '' */
.icon-cards:before {
  content: '\e8c1';
} /* '' */
.icon-home:before {
  content: '\e8c2';
} /* '' */
.icon-bag:before {
  content: '\e8c3';
} /* '' */
.icon-multi_cards:before {
  content: '\e8c4';
} /* '' */
.icon-credit_error:before {
  content: '\e8c5';
} /* '' */
.icon-chat:before {
  content: '\e8c6';
} /* '' */
.icon-credit_card:before {
  content: '\e8c7';
} /* '' */
.icon-sort_2:before {
  content: '\e8c8';
} /* '' */
.icon-open_link:before {
  content: '\e8c9';
} /* '' */
.icon-bank:before {
  content: '\e8ca';
} /* '' */
.icon-cash:before {
  content: '\e8cb';
} /* '' */
.icon-case:before {
  content: '\e8cc';
} /* '' */
.icon-screen:before {
  content: '\e8cd';
} /* '' */
.icon-wallet:before {
  content: '\e8ce';
} /* '' */
.icon-double-chevron-right:before {
  content: '\e8cf';
} /* '' */
.icon-double-chevron-left:before {
  content: '\e8d0';
} /* '' */
.icon-complete_refund:before {
  content: '\e8d1';
} /* '' */
.icon-shield:before {
  content: '\e8d2';
} /* '' */
.icon-camera:before {
  content: '\e8d3';
} /* '' */
.icon-selfie:before {
  content: '\e8d5';
} /* '' */
.icon-add_user:before {
  content: '\e8d6';
} /* '' */
.icon-coin:before {
  content: '\e8d7';
} /* '' */
.icon-lock-filled:before {
  content: '\e8d8';
} /* '' */
.icon-tool:before {
  content: '\e8d9';
} /* '' */
.icon-tasks:before {
  content: '\e8da';
} /* '' */
.icon-text:before {
  content: '\e8db';
} /* '' */
.icon-support:before {
  content: '\e8dc';
} /* '' */
.icon-disable:before {
  content: '\e8dd';
} /* '' */
.icon-sort:before {
  content: '\e8de';
} /* '' */
.icon-shopping:before {
  content: '\e8df';
} /* '' */
.icon-server:before {
  content: '\e8e0';
} /* '' */
.icon-sandbox:before {
  content: '\e8e1';
} /* '' */
.icon-sales:before {
  content: '\e8e2';
} /* '' */
.icon-question-mark:before {
  content: '\e8e4';
} /* '' */
.icon-print:before {
  content: '\e8e5';
} /* '' */
.icon-polygon:before {
  content: '\e8e6';
} /* '' */
.icon-pocket:before {
  content: '\e8e7';
} /* '' */
.icon-phone:before {
  content: '\e8e8';
} /* '' */
.icon-pen:before {
  content: '\e8ea';
} /* '' */
.icon-package:before {
  content: '\e8eb';
} /* '' */
.icon-options:before {
  content: '\e8ec';
} /* '' */
.icon-more_square:before {
  content: '\e8ed';
} /* '' */
.icon-more_circular:before {
  content: '\e8ee';
} /* '' */
.icon-mobile:before {
  content: '\e8ef';
} /* '' */
.icon-link:before {
  content: '\e8f0';
} /* '' */
.icon-id:before {
  content: '\e8f1';
} /* '' */
.icon-hardrive:before {
  content: '\e8f2';
} /* '' */
.icon-desktop:before {
  content: '\e8f5';
} /* '' */
.icon-cpu:before {
  content: '\e8f6';
} /* '' */
.icon-Compliance:before {
  content: '\e8f8';
} /* '' */
.icon-command:before {
  content: '\e8f9';
} /* '' */
.icon-color_picker:before {
  content: '\e8fa';
} /* '' */
.icon-coffee:before {
  content: '\e8fb';
} /* '' */
.icon-clock:before {
  content: '\e8fc';
} /* '' */
.icon-clock_sq:before {
  content: '\e8fd';
} /* '' */
.icon-change_direction:before {
  content: '\e8fe';
} /* '' */
.icon-calculator:before {
  content: '\e901';
} /* '' */
.icon-burger:before {
  content: '\e903';
} /* '' */
.icon-book:before {
  content: '\e904';
} /* '' */
.icon-bold:before {
  content: '\e905';
} /* '' */
.icon-archive:before {
  content: '\e907';
} /* '' */
.icon-notification:before {
  content: '\e908';
} /* '' */
.icon-add_credit_card:before {
  content: '\e90a';
} /* '' */
.icon-user-minus2:before {
  content: '\e90c';
} /* '' */
.icon-wallets-added:before {
  content: '\e90d';
} /* '' */
.icon-equal:before {
  content: '\e9b1';
} /* '' */
.icon-not_equal:before {
  content: '\e9b2';
} /* '' */
.icon-moon:before {
  content: '\f186';
} /* '' */
.icon-sun:before {
  content: '\e80c';
} /* '' */
