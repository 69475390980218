.note,
.caution,
.warning,
.tip,
.important {
  background-color: var(--background) !important;
  border: 1px solid;
  border-radius: 8px;
  color: var(--notification-font-color);
  padding-left: 56px;
  padding-right: 12px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.note:before,
.caution:before,
.warning:before,
.tip:before,
.important:before {
  font-family: fontello;
  width: 34px;
  height: 34px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 12px;
  left: 12px;
}

.note>.title,
.caution>.title,
.warning>.title,
.tip>.title,
.important>.title {
  font-weight: 500;
  font-size: var(--font-size-sm);
  padding: 0;
  margin-bottom: .4em;
}

.note>p,
.caution>p,
.warning>p,
.tip>p,
.important>p {
  font-weight: var(--font-weight);
  font-size: var(--font-size-xs);
  margin-bottom: .7em;
  padding-right: 0;
}

.note p,
.caution p,
.warning p,
.tip p,
.important p,
.note code.literal,
.caution code.literal,
.warning code.literal,
.tip code.literal,
.important code.literal {
  font-weight: var(--font-weight);
  font-size: var(--font-size-xs);
}

.note>p:last-child,
.caution>p:last-child,
.warning>p:last-child,
.tip>p:last-child,
.important>p:last-child {
  margin-bottom: 0;
}

.note ul,
.caution ul,
.warning ul,
.tip ul,
.important ul {
  padding-left: 18px;
}

.tip,
.important {
  border-color: var(--success-color);
}

.tip:before {
  content: '\e863';
  color: var(--success-color);
  background-color: var(--success-bg);
}

.important:before {
  content: '\e846';
  color: var(--success-color);
  background-color: var(--success-bg);
}

.warning {
  border-color: var(--danger-color);
}

.warning:before {
  content: '\e88c';
  color: var(--danger-color);
  background-color: var(--danger-bg);
}

.caution {
  border-color: var(--warning-color);
}

.caution:before {
  content: '\e864';
  color: var(--warning-color);
  background-color: var(--warning-bg);
}

.note {
  border-color: var(--info-color);
}

.note:before {
  content: '\e814';
  color: var(--info-color);
  background-color: var(--info-bg);
}

@media screen and (min-width: 1200px) {

  .note,
  .caution,
  .warning,
  .tip,
  .important {
    max-width: 70%;
  }
}