.site-header {
  display: block;
}

.site-header .logo {
  content: var(--logo);
}

.site-header .navbar-fixed-top {
  border-bottom: var(--aside-border);
  box-shadow: var(--aside-box-shadow);
  min-height: var(--header-height);
  background-color: var(--aside-background-color);
}

.site-header .navbar-brand {
  padding: 20px;
}

.site-header .navbar-toggle {
  position: absolute;
  right: 10px;
  bottom: 0;
  top: 0;
}

.site-header-search {
  float: left;
  margin-top: 15px;
  margin-left: 10px;
  width: 600px;
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.site-header .login-btn {
  position: absolute;
  top: 15px;
  right: 60px;
  width: 83px;
}

.toolbar {
  background-color: var(--background);
}

@media (min-width: 768px) {
  .site-header .login-btn {
    right: 20px;
  }

  .site-header-search {
    display: block;
    width: 600px;
  }
}

@media (max-width: 1050px) {
  .site-header-search {
    width: 300px;
  }
}

@media (max-width: 389px) {
  .site-header .login-btn {
    width: 65px;
    right: 55px;
  }

  .site-header .logo {
    width: 90px;
    display: flex;
    align-items: center;
  }

  .site-header .navbar-brand {
    padding-top: 25px;
  }

}
