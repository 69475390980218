.tabbed-table-container {
  position: relative;
}

.tabbed-table-container p, .tabbed-table-container ul {
  margin: 0 !important;
}

.tabbed-table-container > p {
  margin-bottom: 15px !important;
}

.tabbed-table-container ul li {
  list-style: none;
}

.tabbed-table-container li.listitem {
  position: static;
}

.tabbed-table-container .code-block .paligocode-wrapper {
  height: 370px;
  overflow: auto;
  word-break: break-word;
  position: static !important;
}

.tabbed-table-container .code-block .paligocode-wrapper .hljs {
  word-break: break-word;
}

.tabbed-table-container .code-block .paligocode-wrapper .btn {
  top: 10px !important;
}

.tabbed-table-container .code-block .paligocode-wrapper::-webkit-scrollbar {
  height: 7px;
  width: 7px;
  background: var(--box-background);
}

.tabbed-table-container .code-block .paligocode-wrapper::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 5px;
}

.tabbed-table-container div.code-block {
  border: none;
  margin: 0;
}

.tabbed-table-container ul.code-block {
  border-top-left-radius: 0;
  margin: 0;
  padding: 12px 0;
}

.tabbed-table-container .tabbed-panel > li > div {
  display: none;
}

.tabbed-table-container .itemizedlist {
  padding: 0;
}

.tabbed-table-container ul.tabbed-panel > li > p {
  padding: 8px 16px;
  cursor: pointer;
  font-weight: var(--font-weight);
  font-size: var(--font-size-sm);
  line-height: 20px;
  text-transform: capitalize;
  color: var(--placeholder);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  white-space: nowrap;
}

.tabbed-table-container ul.tabbed-panel.active > li > p {
  background-color: var(--box-background);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary);
  border: 1px solid var(--jumbotron-border-color);
  border-bottom: none;
}

.tabbed-table-container ul.tabbed-panel > li > .itemizedlist {
  position: absolute;
  left: 0;
  right: 0;
}

.tabbed-table-container ul.tabbed-panel.active > li > div {
  display: block;
}

.tabbed-table-container > ul.itemizedlist {
  display: flex;
  min-height: 450px;
}