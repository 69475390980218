/*!
 * Bootstrap Grid v5.3.0-alpha3 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
/*!
 * Bootstrap Utilities v5.3.0-alpha3 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --background: white;
  --font-color: #2e2e2e;
  --color-primary: #7437f5;
  --card-background: white;
  --card-shadow: 0px 0px 20px #00000012;
  --box-background: #f9f9f9;
  --shadow-color: #00000012;
  --btn-hover-color: #7437f5;
  --btn-text-color: white;
  --border-color: #dfdfdf;
  --nav-item-hover-background: #f6f6f7;
  --literal-background: #ebebeb;
  --placeholder: #676568;
  --jumbotron-border-color: #d9d9d9;
  --notification-font-color: #222024;
  --dark-text: #555;
  --logo: url("logo.9339b306.svg");
  --empty-state: url("empty-state.3abc7612.svg");
  --code-string-color: #0da5d7;
  --code-literal-color: #48b901;
  --code-number-color: #7437f5;
  --icon-color: #262327;
  --icon-font-color: #929193;
  --aside-border: 1px solid var(--border-color);
  --aside-box-shadow: none;
  --aside-background-color: white;
  --color-put: #c5862b26;
  --color-post-text: #0dba50;
  --color-post: #0dba501a;
  --color-get: #0988ef1a;
  --color-get-text: #0988ef;
  --color-delete-text: #f2292f;
  --color-delete: #f2292f1a;
  --footer-color: #f9f9f9;
  --jumbotron-background: #fff;
  --success-bg: #eefbee;
  --info-bg: #eff7fe;
  --warning-bg: #fff7ed;
  --danger-bg: #fff5f5;
  --link-color: #7437f5;
  --color-branding-secondary: #ff007a;
  --code-attribute-color: #000;
  --category-background-color: #f9f9f9;
  --all-results-border: #d7d8db;
  --mark-results: #e8dfff;
  --dropdown-background: #fff;
  --pagination-border-color: #e8deff;
  --disabled-color: #909091;
  --result-hover-background: #f6f2ff;
  --gray-text: #676b73;
  --hover-background-color: #f6f6f6;
  --hover-background-color-icon: #454545;
  --icon-number-1: url("1-icon-light.f456ece3.svg") 0px -1px no-repeat;
  --icon-number-2: url("2-icon-light.40ab074b.svg") 0px -1px no-repeat;
  --icon-number-3: url("3-icon-light.e2d28202.svg") 0px -1px no-repeat;
  --icon-number-4: url("4-icon-light.aaffd16b.svg") 0px -1px no-repeat;
  --icon-number-5: url("5-icon-light.d591945d.svg") 0px -1px no-repeat;
  --icon-number-6: url("6-icon-light.0fcea71e.svg") 0px -1px no-repeat;
  --icon-number-7: url("7-icon-light.a2648ace.svg") 0px -1px no-repeat;
  --icon-number-8: url("8-icon-light.f7862232.svg") 0px -1px no-repeat;
  --icon-number-9: url("9-icon-light.8f3eb959.svg") 0px -1px no-repeat;
  --icon-number-10: url("10-icon-light.3ee7a47f.svg") 0px -1px no-repeat;
  --icon-filtered: url("WithValues-filter-icon.2591ce21.svg") no-repeat center;
  --icon-reset: url("refresh.39cf2bfa.svg");
}

html[data-theme="default"] {
  --background: white;
  --font-color: #2e2e2e;
  --color-primary: #7437f5;
  --card-background: white;
  --card-shadow: 0px 0px 20px #00000012;
  --box-background: #f9f9f9;
  --shadow-color: #00000012;
  --btn-hover-color: #7437f5;
  --btn-text-color: white;
  --border-color: #dfdfdf;
  --nav-item-hover-background: #f6f6f7;
  --literal-background: #ebebeb;
  --placeholder: #676568;
  --jumbotron-border-color: #d9d9d9;
  --notification-font-color: #222024;
  --dark-text: #555;
  --logo: url("logo.9339b306.svg");
  --empty-state: url("empty-state.3abc7612.svg");
  --code-string-color: #0da5d7;
  --code-literal-color: #48b901;
  --code-number-color: #7437f5;
  --icon-color: #262327;
  --icon-font-color: #929193;
  --icon-background-color: #f9f9f9;
  --aside-border: 1px solid var(--border-color);
  --aside-box-shadow: none;
  --aside-background-color: white;
  --color-put: #c5862b26;
  --color-post-text: #0dba50;
  --color-post: #0dba5026;
  --color-get: #0988ef26;
  --color-get-text: #0f6ab4;
  --color-delete-text: #a41e22;
  --color-delete: #f2292f26;
  --footer-color: #f9f9f9;
  --jumbotron-background: #fff;
  --success-bg: #eefbee;
  --info-bg: #eff7fe;
  --warning-bg: #fff7ed;
  --danger-bg: #fff5f5;
  --link-color: #7437f5;
  --color-branding-secondary: #ff007a;
  --code-attribute-color: #000;
  --mark-results: #e8dfff;
  --category-background-color: #f9f9f9;
  --all-results-border: #d7d8db;
  --dropdown-background: #fff;
  --pagination-border-color: #e8deff;
  --disabled-color: #909091;
  --hover-background-color: #f6f6f6;
  --result-hover-background: #f6f2ff;
  --gray-text: #676b73;
  --hover-background-color-icon: #454545;
  --icon-number-1: url("1-icon-light.f456ece3.svg") 0px -1px no-repeat;
  --icon-number-2: url("2-icon-light.40ab074b.svg") 0px -1px no-repeat;
  --icon-number-3: url("3-icon-light.e2d28202.svg") 0px -1px no-repeat;
  --icon-number-4: url("4-icon-light.aaffd16b.svg") 0px -1px no-repeat;
  --icon-number-5: url("5-icon-light.d591945d.svg") 0px -1px no-repeat;
  --icon-number-6: url("6-icon-light.0fcea71e.svg") 0px -1px no-repeat;
  --icon-number-7: url("7-icon-light.a2648ace.svg") 0px -1px no-repeat;
  --icon-number-8: url("8-icon-light.f7862232.svg") 0px -1px no-repeat;
  --icon-number-9: url("9-icon-light.8f3eb959.svg") 0px -1px no-repeat;
  --icon-number-10: url("10-icon-light.3ee7a47f.svg") 0px -1px no-repeat;
  --icon-filtered: url("WithValues-filter-icon.2591ce21.svg") no-repeat center;
  --icon-reset: url("refresh.39cf2bfa.svg");
}

html[data-theme="dark"] {
  --background: #1b2338;
  --font-color: #f2f2f2;
  --color-primary: #248fb2;
  --card-background: #222a3e;
  --card-shadow: 0px 0px 24px #050a1ac2;
  --box-background: #222a3e;
  --shadow-color: #050a1ac2;
  --btn-hover-color: #248fb2;
  --btn-text-color: white;
  --border-color: #293044;
  --nav-item-hover-background: #363d50;
  --literal-background: #363d50;
  --placeholder: #f2f2f2;
  --jumbotron-border-color: #3d4158;
  --notification-font-color: white;
  --dark-text: #555;
  --logo: url("logo-light.47c037a1.svg");
  --empty-state: url("empty-state-dark.317df343.svg");
  --code-string-color: #7ac4dc;
  --code-literal-color: #97da6d;
  --code-number-color: #af95ff;
  --icon-color: #248fb2;
  --icon-font-color: #9d9d9e;
  --aside-border: none;
  --aside-box-shadow: 0px 4px 6px #0000001a;
  --aside-background-color: #293044;
  --color-post: #0dba5026;
  --color-post-text: #97da6d;
  --color-get: #0988ef26;
  --color-get-text: #0988ef;
  --color-put-text: #c5862b;
  --color-delete-text: #f2292f;
  --color-delete: #f2292f26;
  --footer-color: #222a3e;
  --jumbotron-background: #222a3e;
  --success-bg: #293044;
  --info-bg: #293044;
  --warning-bg: #293044;
  --danger-bg: #293044;
  --link-color: #14c5ff;
  --color-branding-secondary: #00ff89;
  --code-attribute-color: #fff;
  --mark-results: #248fb2;
  --category-background-color: #1b2338;
  --all-results-border: #676b73;
  --dropdown-background: #363d50;
  --pagination-border-color: #676b73;
  --result-hover-background: #1b233880;
  --disabled-color: #909091;
  --gray-text: #bfc0c3;
  --hover-background-color: #1b233880;
  --hover-background-color-icon: #1b2338;
  --icon-number-1: url("1-icon-dark.5ea12e41.svg") 0px -1px no-repeat;
  --icon-number-2: url("2-icon-dark.fd1f92a5.svg") 0px -1px no-repeat;
  --icon-number-3: url("3-icon-dark.2311cdcf.svg") 0px -1px no-repeat;
  --icon-number-4: url("4-icon-dark.04436b00.svg") 0px -1px no-repeat;
  --icon-number-5: url("5-icon-dark.bb1fd0bc.svg") 0px -1px no-repeat;
  --icon-number-6: url("6-icon-dark.221cd599.svg") 0px -1px no-repeat;
  --icon-number-7: url("7-icon-dark.f641125b.svg") 0px -1px no-repeat;
  --icon-number-8: url("8-icon-dark.fa8756d2.svg") 0px -1px no-repeat;
  --icon-number-9: url("9-icon-dark.d3895d38.svg") 0px -1px no-repeat;
  --icon-number-10: url("10-icon-dark.66c09195.svg") 0px -1px no-repeat;
  --icon-filtered: url("dark-filter-icon-with-values.085499f0.svg") no-repeat center;
  --icon-reset: url("refresh-dark.c83fb73f.svg");
}

* {
  box-sizing: border-box;
}

ul, h1, h2, h3, h4, h5, h6, table, thead, tbody, tr {
  margin: 0;
  padding: 0;
}

main ul {
  margin: 0;
}

a {
  text-decoration: none;
}

a:focus {
  outline: none;
}

img {
  max-width: 100%;
}

button {
  cursor: pointer;
  border: none;
}

button:focus {
  outline: none;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
}

input:focus {
  outline: none;
}

.half-width {
  width: 50%;
}

:root {
  --font-size: 14px;
  --font-size-sm: 16px;
  --font-size-xs: 12px;
  --font-size-md: 18px;
  --font-size-xxs: 10px;
  --font-weight: 400;
  --font-weight-bolder: 500;
  --font-weight-bold: 600;
  --line-height: 24px;
  --font-family: Inter, sans-serif;
  --icon-size-xs: 16px;
  --icon-size-sm: 18px;
  --icon-size-md: 24px;
  --icon-size-lg: 32px;
  --header-height: 64px;
  --footer-height: 198px;
  --mobile-footer-height: 275px;
  --warning-color: #ffac4d;
  --info-color: #62affa;
  --danger-color: #f25050;
  --success-color: #1fd01f;
  --sidebar-max-width: 324px;
}

.blue-text {
  color: var(--color-primary);
}

.strong {
  font-weight: 600 !important;
}

.titlepage .title {
  color: var(--font-color);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  font-family: var(--font-family);
  text-transform: none;
  margin-top: 30px !important;
  margin-bottom: 15px !important;
}

.titlepage .subtitle {
  font-size: 16px;
  font-weight: 500;
}

.body-text {
  font-size: 16px;
  line-height: 25px;
}

a:not(.btn), a:not(.btn):hover, a:not(.btn):focus {
  color: var(--color-primary);
  text-decoration: none;
}

.topic-content section > p {
  margin-bottom: 15px;
}

.topic-content > section > .titlepage:first-child .title {
  font-size: 34px;
  font-weight: 700;
  line-height: 41px;
  margin-top: 0 !important;
  margin-bottom: 20px !important;
}

.topic-content > section > section > .titlepage:first-child .title {
  font-size: 28px;
}

.topic-content > section > section > section > .titlepage:first-child .title {
  font-size: 20px;
}

p.section-title {
  color: var(--font-color);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

html[data-theme="dark"] .hljs-keyword, html[data-theme="dark"] .hljs-built_in {
  color: var(--code-literal-color);
}

html[data-theme="dark"] .hljs-title {
  color: var(--code-string-color);
}

html[data-theme="dark"] .hljs-comment {
  color: var(--danger-color);
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4, h5, .h5 {
  font-family: var(--font-family);
  color: var(--font-color);
  text-transform: none;
}

h1, .h1 {
  font-size: 34px;
  font-weight: 700;
}

h2, .h2 {
  font-weight: var(--font-weight-bold);
  font-size: 26px;
}

h3, .h3 {
  font-weight: var(--font-weight-bold);
  font-size: 24px;
}

h4, .h4 {
  font-weight: var(--font-weight-bold);
  font-size: 20px;
}

.v-none {
  visibility: hidden;
}

.mt-m-30 {
  margin-top: -30px !important;
}

.no-border {
  border: none !important;
}

html, body {
  height: 100%;
}

body {
  background-color: var(--background);
  color: var(--font-color);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--line-height);
  font-family: var(--font-family);
}

ul.card-container, section.card-container {
  padding: 10px;
  display: flex;
  overflow-y: auto;
}

.card-container .titlepage {
  display: none;
}

.card {
  background: var(--card-background);
  box-shadow: var(--card-shadow);
  border-radius: 12px;
  flex-direction: column;
  flex: 1 1 0;
  align-items: flex-start;
  padding: 24px 30px;
  display: flex;
}

.card.link {
  cursor: pointer;
  color: inherit !important;
}

.card .titlepage {
  display: none;
}

.card ul {
  padding: 0;
}

.card ul li {
  list-style: none;
}

.card a {
  color: var(--font-color);
}

.blue-text a, .blue-text {
  color: var(--color-primary);
}

.card .card-title {
  text-transform: capitalize;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
}

.card .card-body {
  font-weight: 400;
  font-size: var(--font-size);
  margin-bottom: 5px;
  line-height: 25px;
}

.card .card-footer {
  margin-top: auto;
}

.card .icon-container {
  background: var(--icon-background-color);
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-bottom: 15px;
  display: flex;
  overflow: hidden;
}

.icon-container .icon {
  font-size: 25px;
}

.card-container .card {
  margin: 0 15px;
}

.card-container .card:first-of-type {
  margin: 0 15px 0 0 !important;
}

.card-container .card:last-child {
  margin: 0 0 0 15px !important;
}

div.card-container {
  margin: 0 !important;
  padding: 0 !important;
}

.card-link {
  display: none;
}

.card .icon {
  color: var(--color-branding-secondary) !important;
}

@media (width <= 767px) {
  .card-container {
    flex-direction: column;
  }

  .card-container .card, .card-container .card:first-of-type, .card-container .card:last-child {
    margin: 15px 0 !important;
  }
}

.btn {
  font-weight: 500;
  font-size: var(--font-size);
  cursor: pointer;
  border-radius: 8px;
  line-height: 20px;
}

.btn-primary, .btn-primary-arrow {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--btn-text-color);
}

.btn-primary:hover, .btn-primary-arrow:hover, .btn-primary:active, .btn-primary-arrow:active, .btn-primary:focus, .btn-primary-arrow:focus {
  background-color: var(--btn-hover-color) !important;
  border-color: var(--btn-hover-color) !important;
  color: var(--btn-text-color) !important;
}

.btn-primary-arrow:after {
  speak: never;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  width: 1em;
  font-family: fontello;
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  display: inline-block;
}

.list-table, .list-table table {
  table-layout: fixed;
  width: 100%;
}

.list-table th {
  border-top: none;
}

.list-table th, .list-table td {
  border-color: var(--jumbotron-border-color);
  padding: 30px;
}

.list-table th p, .list-table td p {
  font-style: normal;
  font-weight: 400;
}

.list-table th a, .list-table td a {
  text-transform: capitalize;
  color: var(--color-primary);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.list-table th ul, .list-table td ul {
  margin-top: 15px;
  padding: 0;
}

.list-table th ul li, .list-table td ul li {
  padding: 3px 0;
  list-style: none;
}

.list-table th ul li a, .list-table td ul li a {
  color: var(--color-primary);
}

.list-table tr:last-child td {
  border-bottom: none;
}

.error-list > ul, .error-codes > ul {
  padding-left: 0;
  list-style: none !important;
}

.error-list > ul > li > p:nth-child(2), .error-codes > ul > li > p:nth-child(2) {
  padding-left: 15px;
}

.error-list table, .error-list, .error-codes table, .error-codes {
  margin-top: 15px;
}

.error-list table .h4, .error-list .h4, .error-codes table .h4, .error-codes .h4 {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}

.error-list table td, .error-codes table td {
  border: none;
  padding-bottom: 15px;
}

.error-list table td p:not(:first-child), .error-codes table td p:not(:first-child) {
  padding-left: 15px;
}

.error-list.active, .error-codes.active {
  display: block !important;
}

@media (width <= 767px) {
  .list-table tr {
    flex-direction: column;
    display: flex;
  }
}

.link-arrow {
  color: var(--color-primary);
}

.link-arrow:after {
  speak: never;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  width: 1em;
  font-family: fontello;
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  display: inline-block;
}

.jumbotron-container {
  border: 1px solid var(--jumbotron-border-color);
  background-color: var(--jumbotron-background);
  border-radius: 8px;
  margin-top: 30px;
  padding: 24px 15px 15px 24px;
}

.jumbotron-container a {
  margin-top: 5px;
  display: inline-block;
}

.jumbotron-container .section-title {
  font-size: 24px;
  font-weight: 600;
  margin-top: 0 !important;
  margin-bottom: 20px !important;
}

.jumbotron-container .titlepage {
  display: none;
}

a.link {
  color: var(--link-color) !important;
}

a.btn {
  color: var(--btn-text-color) !important;
}

@media (width >= 500px) {
  .page-toc .pager, .page-toc .site-footer, .page-toc main article {
    width: 100%;
  }

  .page-toc main:has(.section-nav-container) article {
    width: 80% !important;
  }
}

section[id*="idm"]:before {
  content: "";
  visibility: hidden;
  display: block;
  height: 0 !important;
  margin-top: 0 !important;
}

input#search-bar, input#side-search-bar {
  border: 1px solid var(--border-color);
  color: var(--font-color);
  background-color: var(--nav-item-hover-background);
  border-radius: 4px;
  width: 100%;
  height: 34px;
  padding-left: 40px;
}

.site-header-search .algolia-autocomplete {
  width: 100%;
}

.search-input-container {
  position: relative;
}

.search-result-breadcrumbs.recent {
  color: var(--gray-text);
  font-size: 12px;
}

.search-result-data {
  padding: 5px 5px 5px 15px;
  display: flex;
}

.search-result-data.all {
  white-space: initial;
  flex-direction: column;
  row-gap: 5px;
  padding: 15px;
  display: flex;
}

.search-result-data.recent, .search-result-data.popular {
  margin: 13px;
  padding-left: 15px;
}

.search-result-container.recent {
  margin: 0;
}

.search-results-separating-line.recent {
  width: 95%;
}

.search-result-breadcrumbs.recent .breadcrumb-text.mark {
  color: var(--gray-text);
}

.search-input-container .icon-search:before {
  position: absolute;
  top: 8px;
  left: 8px;
}

.site-sidebar-search .icon-search:before {
  z-index: 1;
  position: absolute;
  top: 29px;
  left: 21px;
}

.site-sidebar-search .icon-delete {
  position: absolute;
  top: 25px;
  right: 20px;
}

.search-input-container .icon-delete:before {
  position: absolute;
  top: 8px;
  right: 8px;
}

.search-dropdown {
  background-color: var(--dropdown-background);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  width: 100%;
  max-height: 600px;
  margin-top: 10px;
  padding-top: 10px;
  display: none;
  position: absolute;
  overflow-y: auto;
}

.sidebar-search-dropdown {
  background-color: var(--dropdown-background);
  z-index: 1;
  width: 100%;
  margin-top: 70px;
  display: none;
  position: absolute;
}

.all-results-modal {
  background-color: var(--dropdown-background);
  border: 1px solid var(--border-color);
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: calc(100% - 63px);
  margin-top: 64px;
  padding-top: 10px;
  display: none;
  position: fixed;
  bottom: 0;
  overflow-y: auto;
}

.all-results-container {
  background-color: var(--dropdown-background);
  flex-direction: column;
  width: 60%;
  height: 85%;
  margin: 0 auto;
  display: flex;
}

.exit-all-result {
  justify-content: flex-end;
  width: 100%;
  padding: 10px 20px 0 0;
  display: flex;
}

.site-header-all-results {
  height: 100%;
}

.show-all-results-container {
  border-top: 1px solid var(--all-results-border);
  cursor: pointer;
  background-color: var(--dropdown-background);
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;
  display: flex;
  position: sticky;
  bottom: 0;
}

.show-all-results {
  color: var(--mark-results);
  font-size: 13px;
}

mark {
  background-color: var(--mark-results);
  margin: 0;
  padding: 0;
}

form input#search-bar::placeholder, form input#side-search-bar::placeholder {
  color: var(--placeholder);
}

form input#search-bar:focus, form input#side-search-bar:focus {
  border-color: var(--color-primary);
}

.searchresults {
  margin-top: 15px;
}

.search-result-title {
  font-family: var(--font-family);
  color: var(--font-color);
  font-size: 14px;
  font-weight: 500;
}

.search-result-title .result-mark {
  background-color: var(--mark-results);
  margin: 0;
  padding: 0;
}

.search-result-title.all {
  font-size: 24px;
  font-weight: 700;
}

.search-result-body {
  color: var(--gray-text);
}

.search-result-body.all {
  font-size: 14px;
  line-height: 16px;
}

.search-result-breadcrumbs.all {
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 16px;
}

.search-results-headline {
  font-family: var(--font-family);
  color: var(--gray-text);
  margin-left: 15px;
  font-size: 12px;
  font-weight: 500;
}

.search-result-container {
  background-color: var(--dropdown-background);
  border-radius: 4px;
  margin: 15px;
  overflow-x: visible;
}

.search-result-container.all {
  border-radius: 4px;
  margin: 0;
}

.result-separator {
  background-color: var(--all-results-border);
  width: 100%;
  height: 1px;
}

.search-result-container:hover {
  background-color: var(--result-hover-background);
}

.category {
  font-family: var(--font-family);
  margin: 5px 0 0 20px;
  list-style-type: none;
}

.all-results-category-container {
  background-color: var(--dropdown-background);
  color: var(--font-color);
  border-bottom: 1px solid var(--all-results-border);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
  display: flex;
}

.all-results-category-container:hover {
  background-color: var(--hover-background-color);
}

.all-results-category-container:hover .category-length {
  background-color: var(--hover-background-color-icon);
  color: var(--btn-text-color);
}

.all-results-category-container.active {
  border-bottom: 2px solid var(--color-primary);
  color: var(--color-primary);
  font-weight: 500;
}

.all-results-category-container.active .category-length {
  background-color: var(--color-primary);
  color: var(--btn-text-color);
}

.all-results-category-container.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: .5;
}

.all-results-category-container.disabled .category-length {
  background-color: var(--hover-background-color-icon);
  color: var(--btn-text-color);
}

.selected-searchresultitem {
  background-color: var(--box-background) !important;
}

.selected-searchresultitem .searchresulttitle, .selected-searchresultitem .search-result-breadcrumbs {
  color: var(--font-color) !important;
}

.searchresultitem {
  border-color: var(--jumbotron-border-color) !important;
}

.search-highlight {
  font-style: normal !important;
}

.category-container {
  background-color: var(--category-background-color);
  color: var(--gray-text);
  display: flex;
}

.all-results-category {
  white-space: nowrap;
  height: 40px;
  margin-bottom: 10px;
  display: flex;
  position: relative;
  overflow: scroll hidden;
}

.all-results-category::-webkit-scrollbar {
  display: none;
}

.all-result-header {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  top: 0;
}

.pagination-container {
  background-color: var(--dropdown-background);
  text-align: center;
  justify-content: center;
  align-items: self-start;
  width: 100%;
  height: 8%;
  margin-top: auto;
  padding-top: 10px;
  display: flex;
  bottom: 0;
  left: 0;
}

.pagination-button {
  background-color: var(--dropdown-background);
}

.pagination-number {
  background-color: var(--dropdown-background);
  width: 34px;
  margin: 0 5px;
  font-size: 14px;
}

.pagination-number.active {
  border: 1px solid var(--pagination-border-color);
  color: var(--color-primary);
  border-radius: 25%;
  box-shadow: 0 0 1px #0000000d, 0 1px 6px #0000000d;
}

.pagination-number:hover {
  color: var(--color-primary);
}

.pagination-button-next.disabled, .pagination-button-prev.disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: var(--disabled-color);
}

.pagination-button-next, .pagination-button-prev {
  color: var(--color-primary);
  background-color: var(--dropdown-background);
}

.pagination-button-next span:before, .pagination-button-prev span:before {
  font-size: var(--icon-size-lg);
}

.category-length {
  background-color: var(--result-hover-background);
  color: var(--font-color);
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  max-height: 22px;
  margin-left: 15px;
  padding: 4px 8px;
  display: flex;
}

.search-results-all {
  overflow: auto;
}

.all-results-headline {
  margin: 30px;
  font-size: 34px;
  font-weight: 700;
}

.all-results-title-container {
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.search-result-icon.all {
  color: var(--font-color);
  margin: 10px 10px 10px 0;
}

.search-result-icon {
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  display: flex;
}

.all-results-title-container .search-result-icon:before, .search-result-data .search-result-icon:before {
  font-size: var(--icon-size-md);
  color: var(--gray-text);
}

.search-result-breadcrumbs {
  color: var(--gray-text);
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.breadcrumb-text.mark {
  color: var(--color-primary);
  background-color: inherit;
  margin-bottom: 2px;
}

.search-results-empty {
  content: var(--empty-state);
}

.search-results-empty-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px;
  display: flex;
}

.search-results-empty-container span {
  color: var(--gray-text);
  text-align: center;
}

.pagination-items {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.exit-all-result .exit-results-modal:before {
  font-size: var(--icon-size-md);
  color: var(--gray-text);
}

.sidebar-search-dropdown .search-result-breadcrumbs {
  font-size: 12px;
}

@media (width <= 980px) {
  .all-results-category-container {
    justify-content: center;
    align-items: center;
    min-width: 150px;
    display: flex;
  }

  .all-results-container {
    width: 100%;
    height: 85%;
  }

  .search-result-title.all {
    font-size: 16px;
  }

  .search-result-breadcrumbs.all {
    font-size: 14px;
  }

  .all-results-headline {
    margin: 0 0 30px 15px;
  }

  .all-results-category {
    display: flex;
    overflow: auto;
  }

  .all-results-container {
    white-space: nowrap;
  }

  .all-results-category {
    height: 50%;
    display: flex;
    overflow-x: auto;
  }

  .all-results-category-container:hover {
    background-color: initial;
  }

  .all-results-category-container:hover .category-length:hover {
    background-color: var(--result-hover-background);
    color: initial;
  }
}

.nav-site-sidebar {
  padding: 0 8px 0 12px;
}

.nav-site-sidebar li a {
  color: var(--font-color);
  border-radius: 4px;
  margin-bottom: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.nav-site-sidebar li.active > a, .nav-site-sidebar li a:hover {
  background-color: var(--nav-item-hover-background);
  transition: background-color .2s ease-in-out;
}

.nav-site-sidebar li.active > a, .nav-site-sidebar li:has(li.active) > a {
  font-weight: 500;
  color: var(--link-color) !important;
}

.nav-site-sidebar > li > a {
  font-weight: 600;
  font-size: var(--font-size-xs);
}

.nav-site-sidebar [class^="icon-"]:before, .nav-site-sidebar [class*=" icon-"]:before {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.toc > li > .topic-link > .glyphicon {
  margin-top: 4px;
}

.nav-site-sidebar .active > a {
  color: var(--link-color) !important;
}

.nav-site-sidebar ul ul ul ul a {
  padding-left: 75px;
}

.section-nav.nav li > a {
  color: var(--font-color);
}

.section-nav.nav li.active > a {
  color: var(--color-primary) !important;
}

.section-nav.nav li.active > a:before, .section-nav.nav > li:first-child {
  display: none;
}

.section-nav.nav > li:nth-child(2) {
  margin-top: 15px;
}

.breadcrumb {
  font-size: var(--font-size);
}

.breadcrumb .breadcrumb-link a {
  color: var(--placeholder);
}

.breadcrumb .breadcrumb-link a:hover, .breadcrumb .breadcrumb-node {
  color: var(--link-color);
}

.breadcrumb > li + li:before {
  color: var(--placeholder);
  content: "";
  margin-left: -5px;
  padding: 0 10px;
  font-family: fontello;
}

@media (width >= 2000px) {
  .breadcrumb-container {
    max-width: 70%;
    margin: 0 auto;
  }
}

.note, .caution, .warning, .tip, .important {
  color: var(--notification-font-color);
  border: 1px solid;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  min-height: 60px;
  padding-left: 56px;
  padding-right: 12px;
  display: flex;
  background-color: var(--background) !important;
}

.note:before, .caution:before, .warning:before, .tip:before, .important:before {
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  font-family: fontello;
  display: flex;
  top: 12px;
  left: 12px;
}

.note > .title, .caution > .title, .warning > .title, .tip > .title, .important > .title {
  font-weight: 500;
  font-size: var(--font-size-sm);
  margin-bottom: .4em;
  padding: 0;
}

.note > p, .caution > p, .warning > p, .tip > p, .important > p {
  font-weight: var(--font-weight);
  font-size: var(--font-size-xs);
  margin-bottom: .7em;
  padding-right: 0;
}

.note p, .caution p, .warning p, .tip p, .important p, .note code.literal, .caution code.literal, .warning code.literal, .tip code.literal, .important code.literal {
  font-weight: var(--font-weight);
  font-size: var(--font-size-xs);
}

.note > p:last-child, .caution > p:last-child, .warning > p:last-child, .tip > p:last-child, .important > p:last-child {
  margin-bottom: 0;
}

.note ul, .caution ul, .warning ul, .tip ul, .important ul {
  padding-left: 18px;
}

.tip, .important {
  border-color: var(--success-color);
}

.tip:before {
  content: "";
  color: var(--success-color);
  background-color: var(--success-bg);
}

.important:before {
  content: "";
  color: var(--success-color);
  background-color: var(--success-bg);
}

.warning {
  border-color: var(--danger-color);
}

.warning:before {
  content: "";
  color: var(--danger-color);
  background-color: var(--danger-bg);
}

.caution {
  border-color: var(--warning-color);
}

.caution:before {
  content: "";
  color: var(--warning-color);
  background-color: var(--warning-bg);
}

.note {
  border-color: var(--info-color);
}

.note:before {
  content: "";
  color: var(--info-color);
  background-color: var(--info-bg);
}

@media screen and (width >= 1200px) {
  .note, .caution, .warning, .tip, .important {
    max-width: 70%;
  }
}

.accordion:not(:last-child) .panel {
  border-bottom: 1px solid var(--jumbotron-border-color);
}

.accordion:last-child .panel {
  border-bottom: none;
}

.accordion ul {
  margin-bottom: 10px !important;
}

.accordion p {
  margin-bottom: 1em;
}

.accordion p[class*="datatype-"] {
  margin-bottom: 0;
}

.accordion .panel-heading {
  padding: 0;
  position: relative;
}

.accordion .panel {
  background-color: var(--background);
}

.accordion .panel-heading .title {
  color: var(--notification-font-color);
  font-size: 20px;
  font-family: var(--font-family);
  margin: 20px 0 40px !important;
}

.accordion .panel-heading.active .title {
  margin-bottom: 24px !important;
}

.accordion .panel-heading .title > .title:before {
  content: "";
  font-family: fontello;
  position: absolute;
  right: 3px;
  margin-right: 0 !important;
}

.accordion .panel-heading.active .title > .title:before {
  content: "";
}

.accordion .panel-body {
  margin: 0 0 40px;
  padding: 0;
}

h3.title.icon-1, h4.title.icon-1, h5.title.icon-1, h6.title.icon-1 {
  background: var(--icon-number-1);
  background-size: contain;
  height: 30px;
  padding-top: 2px;
  padding-left: 36px;
}

h3.title.icon-2, h4.title.icon-2, h5.title.icon-2, h5.title.icon-2, h6.title.icon-2 {
  background: var(--icon-number-2);
  background-size: contain;
  height: 30px;
  padding-top: 2px;
  padding-left: 36px;
}

h3.title.icon-3, h4.title.icon-3, h5.title.icon-3, h6.title.icon-3 {
  background: var(--icon-number-3);
  background-size: contain;
  height: 30px;
  padding-top: 2px;
  padding-left: 36px;
}

h3.title.icon-4, h4.title.icon-4, h5.title.icon-4, h6.title.icon-4 {
  background: var(--icon-number-4);
  background-size: contain;
  height: 30px;
  padding-top: 2px;
  padding-left: 36px;
}

h3.title.icon-5, h4.title.icon-5, h5.title.icon-5, h6.title.icon-5 {
  background: var(--icon-number-5);
  background-size: contain;
  height: 30px;
  padding-top: 2px;
  padding-left: 36px;
}

h3.title.icon-6, h4.title.icon-6, h5.title.icon-6, h6.title.icon-6 {
  background: var(--icon-number-6);
  background-size: contain;
  height: 30px;
  padding-top: 2px;
  padding-left: 36px;
}

h3.title.icon-7, h4.title.icon-7, h5.title.icon-7, h6.title.icon-7 {
  background: var(--icon-number-7);
  background-size: contain;
  height: 30px;
  padding-top: 2px;
  padding-left: 36px;
}

h3.title.icon-8, h4.title.icon-8, h5.title.icon-8, h6.title.icon-8 {
  background: var(--icon-number-8);
  background-size: contain;
  height: 30px;
  padding-top: 2px;
  padding-left: 36px;
}

h3.title.icon-9, h4.title.icon-9, h5.title.icon-9, h6.title.icon-9 {
  background: var(--icon-number-9);
  background-size: contain;
  height: 30px;
  padding-top: 2px;
  padding-left: 36px;
}

h3.title.icon-10, h4.title.icon-10, h5.title.icon-10, h6.title.icon-10 {
  background: var(--icon-number-10);
  background-size: contain;
  height: 30px;
  padding-top: 2px;
  padding-left: 36px;
}

.numbered {
  color: var(--notification-font-color);
  font-family: var(--font-family);
  align-items: center;
  margin-top: 10px;
  margin-bottom: 12px;
  display: flex;
  font-size: var(--font-size-md) !important;
  font-weight: var(--font-weight-bolder) !important;
}

.numbered:before {
  --size: 26px;
  background-color: var(--color-primary);
  color: #fff;
  font-size: var(--font-size);
  left: calc(-1 * var(--size)  - 10px);
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  text-align: center;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 8px;
  top: 0;
}

.numbered.n1:before {
  content: "1";
}

.numbered.n2:before {
  content: "2";
}

.numbered.n3:before {
  content: "3";
}

.numbered.n4:before {
  content: "4";
}

.numbered.n5:before {
  content: "5";
}

.numbered.n6:before {
  content: "6";
}

.numbered.n7:before {
  content: "7";
}

.numbered.n8:before {
  content: "8";
}

.numbered.n9:before {
  content: "9";
}

.numbered.n10:before {
  content: "10";
}

.hljs, .hljs-attr {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--placeholder) !important;
}

.hljs-number {
  color: var(--code-number-color) !important;
}

.hljs-string {
  color: var(--code-string-color) !important;
}

.hljs-literal {
  color: var(--code-literal-color) !important;
}

.hljs-subst, .hljs-attribute {
  color: var(--code-attribute-color) !important;
}

.no-copy .btn-primary {
  display: none;
}

.code-block, ul.endpoint, section.endpoint {
  border: 1px solid var(--jumbotron-border-color);
  border-radius: 12px;
  padding: 0;
  background-color: var(--box-background) !important;
}

.code-block button, .code-block button:focus {
  color: var(--icon-color) !important;
}

.code-block.standalone + button, .code-block.standalone + button:focus {
  cursor: pointer;
  border: none;
  color: var(--icon-color) !important;
  background-color: #0000 !important;
  top: 10px !important;
  right: 10px !important;
}

ul.endpoint, section.endpoint {
  margin-bottom: 15px;
}

ul.endpoint .titlepage, section.endpoint .titlepage {
  display: none;
}

.code-block-title, ul.endpoint p:first-of-type, section.endpoint p:first-of-type {
  border-bottom: 1px solid var(--border-color);
  background-color: var(--background);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.code-block .hljs, ul.endpoint .hljs, section.endpoint .hljs {
  margin: 0;
}

ul.endpoint p:not(:first-of-type), section.endpoint p:not(:first-of-type) {
  padding: 10px;
}

ul.endpoint li, .api-method-example li {
  list-style: none;
}

.code-block .paligocode-wrapper .hljs, ul.endpoint .paligocode-wrapper .hljs, section.endpoint .paligocode-wrapper .hljs {
  border-radius: 0 12px 12px;
  background-color: var(--box-background) !important;
}

.code-block .paligocode-wrapper, ul.endpoint .paligocode-wrapper, section.endpoint .paligocode-wrapper {
  padding: 10px;
}

.code-block button[style] {
  background-color: var(--background);
  border: none;
  top: -33px !important;
  right: 10px !important;
}

.code-block button i {
  color: var(--icon-color);
}

.code-block button:hover, .code-block button:active, .code-block button:focus {
  background-color: var(--jumbotron-border-color) !important;
}

.http-post, .http-get, .http-put, .http-delete {
  margin-bottom: 0;
  font-weight: 400;
  line-height: 20px;
}

.http-post:before, .http-get:before, .http-put:before, .http-delete:before {
  border-radius: 100px;
  margin-right: 15px;
  padding: 4px 8px;
  font-size: 10px;
}

.http-post:after, .http-get:after, .http-put:after, .http-delete:after {
  border-radius: 100px;
  margin-left: 15px;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 600;
}

p.http-post:before {
  content: "POST";
  background-color: var(--color-post);
  color: var(--color-post-text);
}

p.http-get:before {
  content: "GET";
  background-color: var(--color-get);
  color: var(--color-get-text);
}

p.http-put:before {
  content: "PUT";
  background-color: var(--color-put);
  color: var(--color-put-text);
}

p.http-delete:before {
  content: "DELETE";
  background-color: var(--color-delete);
  color: var(--color-delete-text);
}

.topic-link.http-post, .topic-link.http-put, .topic-link.http-delete, .topic-link.http-get {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.topic-link.http-post:after, .topic-link.http-put:after, .topic-link.http-delete:after, .topic-link.http-get:after {
  justify-content: center;
  align-items: center;
  height: 22px;
  display: flex;
}

.topic-link.http-post:after {
  content: "POST";
  background-color: var(--color-post);
  color: var(--color-post-text);
}

.topic-link.http-put:after {
  content: "PUT";
  background-color: var(--color-put);
  color: var(--color-put-text);
}

.topic-link.http-delete:after {
  content: "DELETE";
  background-color: var(--color-delete);
  color: var(--color-delete-text);
}

.topic-link.http-get:after {
  content: "GET";
  background-color: var(--color-get);
  color: var(--color-get-text);
}

ul.endpoint p:first-of-type, section.endpoint p:first-of-type {
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: var(--box-background) !important;
}

ul.endpoint, section.endpoint {
  background-color: var(--background) !important;
}

ul.endpoint select, section.endpoint select {
  background-color: var(--background);
  color: var(--font-color);
}

.api-method-example .itemizedlist {
  padding: 0;
}

div.api-method-example, section.api-method-example {
  display: none !important;
}

.api-method-example.active {
  display: block !important;
}

#api-select {
  border: none;
  width: calc(100% - 10px);
  margin: 5px;
}

.api-method-example ul.itemizedlist li:first-of-type {
  display: none;
}

.api-method-example .informalexample .paligocode-wrapper {
  scrollbar-width: thin;
  margin-bottom: 7px;
  overflow: auto;
  position: inherit !important;
}

.api-method-example .informalexample .paligocode-wrapper::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 5px;
}

.api-method-example .example-request .paligocode-wrapper {
  max-height: 25vh;
}

.api-method-example .example-response .paligocode-wrapper {
  max-height: 33.3333vh;
}

.api-method-example .example-webhook .paligocode-wrapper {
  max-height: 50vh;
}

.api-method-example ::-webkit-scrollbar {
  background: var(--box-background);
  width: 7px;
  height: 7px;
}

.api-method-example ::-webkit-scrollbar-thumb {
  background: var(--box-background);
  -webkit-box-shadow: 0px 1px 2px var(--box-background);
  -webkit-border-radius: 1ex;
}

.api-method-example ::-webkit-scrollbar-corner {
  background: var(--box-background);
}

.api-method-example .code-block button[style] {
  top: 8px !important;
  right: 5px !important;
}

.api-method-example .example-request:before, .api-method-example .example-response:before, .api-method-example .example-webhook:before {
  border-bottom: 1px solid var(--border-color);
  background-color: var(--background);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: block;
}

.api-method-example .example-request:before {
  content: "Request";
}

.api-method-example .example-response:before {
  content: "Response";
}

.api-method-example .example-webhook:before {
  content: attr(data-content);
}

code.literal {
  background-color: var(--literal-background);
  color: var(--placeholder);
  font-size: var(--font-size);
  border-radius: 4px;
  padding: 3px 4px;
}

.inlinemediaobject {
  vertical-align: unset;
}

.tabbed-table-container {
  position: relative;
}

.tabbed-table-container p, .tabbed-table-container ul {
  margin: 0 !important;
}

.tabbed-table-container > p {
  margin-bottom: 15px !important;
}

.tabbed-table-container ul li {
  list-style: none;
}

.tabbed-table-container li.listitem {
  position: static;
}

.tabbed-table-container .code-block .paligocode-wrapper {
  word-break: break-word;
  height: 370px;
  overflow: auto;
  position: static !important;
}

.tabbed-table-container .code-block .paligocode-wrapper .hljs {
  word-break: break-word;
}

.tabbed-table-container .code-block .paligocode-wrapper .btn {
  top: 10px !important;
}

.tabbed-table-container .code-block .paligocode-wrapper::-webkit-scrollbar {
  background: var(--box-background);
  width: 7px;
  height: 7px;
}

.tabbed-table-container .code-block .paligocode-wrapper::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 5px;
}

.tabbed-table-container div.code-block {
  border: none;
  margin: 0;
}

.tabbed-table-container ul.code-block {
  border-top-left-radius: 0;
  margin: 0;
  padding: 12px 0;
}

.tabbed-table-container .tabbed-panel > li > div {
  display: none;
}

.tabbed-table-container .itemizedlist {
  padding: 0;
}

.tabbed-table-container ul.tabbed-panel > li > p {
  cursor: pointer;
  font-weight: var(--font-weight);
  font-size: var(--font-size-sm);
  text-transform: capitalize;
  color: var(--placeholder);
  white-space: nowrap;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 8px 16px;
  line-height: 20px;
}

.tabbed-table-container ul.tabbed-panel.active > li > p {
  background-color: var(--box-background);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary);
  border: 1px solid var(--jumbotron-border-color);
  border-bottom: none;
}

.tabbed-table-container ul.tabbed-panel > li > .itemizedlist {
  position: absolute;
  left: 0;
  right: 0;
}

.tabbed-table-container ul.tabbed-panel.active > li > div {
  display: block;
}

.tabbed-table-container > ul.itemizedlist {
  min-height: 450px;
  display: flex;
}

.query-params-table {
  margin-bottom: 2em;
}

.query-params-table table {
  width: 100%;
}

.query-params-table th {
  font-weight: var(--font-weight-bold);
  border-top: none;
}

.query-params-table th, .query-params-table td {
  padding: 14px 20px;
}

.query-params-table td:first-child {
  border-left-style: solid;
}

.query-params-table td:last-child {
  border-right-style: solid;
}

.query-params-table .query-param {
  background-color: var(--nav-item-hover-background);
  border-radius: 100px;
  padding: 4px 8px;
}

.site-header .theme-switcher {
  border: 1px solid var(--border-color);
  background-color: var(--box-background);
  cursor: pointer;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  width: 74px;
  height: 36px;
  padding: 5px;
  font-family: fontello;
  display: flex;
  position: absolute;
  top: 15px;
  right: 160px;
}

html[data-theme="dark"] .site-header .theme-switcher {
  background-color: var(--background);
}

html[data-theme="dark"] .site-header .theme-switcher .slider {
  transform: translate(-35px);
}

.site-header .theme-switcher .slider {
  background-color: var(--color-primary);
  border-radius: 5px;
  width: 30px;
  height: 28px;
  transition: all .2s ease-out;
  position: absolute;
  right: 3px;
}

.site-header .theme-switcher:before {
  content: "";
  color: var(--icon-font-color);
  z-index: 1;
  margin-left: 7px;
  font-size: 16px;
}

html[data-theme="dark"] .site-header .theme-switcher:before {
  color: var(--btn-text-color);
}

.site-header .theme-switcher:after {
  content: "";
  z-index: 1;
  color: var(--btn-text-color);
  margin-right: 3px;
  font-size: 19px;
}

html[data-theme="dark"] .site-header .theme-switcher:after {
  color: var(--icon-font-color);
}

@media (width >= 768px) {
  .site-header .theme-switcher {
    right: 130px;
  }
}

@media (width <= 389px) {
  .site-header .theme-switcher {
    right: 130px;
  }
}

.inner-accordion > p {
  cursor: pointer;
  margin-bottom: 40px;
  position: relative;
}

.inner-accordion > p:after {
  font-family: fontello;
  font-size: var(--font-size-sm);
  content: "";
  margin-right: 8px;
  position: absolute;
  bottom: -30px;
  left: 0;
}

.inner-accordion > p:before {
  content: "Show child parameters";
  font-size: var(--font-size-xs);
  position: absolute;
  bottom: -30px;
  left: 25px;
}

.inner-accordion[aria-expanded="true"] > p:after {
  content: "";
}

.inner-accordion[aria-expanded="true"] > p:before {
  content: "Hide child parameters";
}

#feedback-modal .close {
  top: 5px;
  right: 5px;
}

#feedback-modal label {
  font-weight: 500;
}

.modal-content {
  background-color: var(--background);
}

.modal-title {
  color: var(--font-color);
}

.modal-footer, .modal-header {
  border-color: var(--jumbotron-border-color);
}

.close {
  color: var(--font-color);
}

td:has(.preloader-container) {
  position: relative;
}

.preloader-container {
  background-color: var(--footer-color);
  z-index: 1;
  visibility: hidden;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 30px;
  position: absolute;
  overflow: hidden;
  display: flex !important;
}

.preloader-container.show {
  visibility: visible;
}

.loader, .loader:before, .loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation: 1.8s ease-in-out infinite spinner-keyframe;
}

.loader {
  color: var(--font-color);
  margin: 80px auto;
  font-size: 10px;
  animation-delay: -.16s;
  position: relative;
  top: -4em;
}

.loader:before, .loader:after {
  content: "";
  position: absolute;
}

.loader:before {
  animation-delay: -.32s;
  left: -3.5em;
}

.loader:after {
  left: 3.5em;
}

@keyframes spinner-keyframe {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em;
  }
}

.popover {
  font-family: var(--font-family);
  font-size: var(--font-size-xs);
  color: var(--font-color);
  background-color: var(--aside-background-color);
}

.popover .popover-title {
  background-color: var(--aside-background-color);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  border-bottom: none;
  padding-top: 15px;
  padding-bottom: 0;
}

.popover .popover-content {
  padding-bottom: 0;
}

.popover .arrow:after {
  border-bottom-color: var(--aside-background-color) !important;
}

.blockquote {
  font-size: var(--font-size);
}

.blockquote p {
  margin: 0 0 .7em;
}

#onetrust-consent-sdk .ot-pc-logo {
  display: none !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk {
  inset: auto 20px 20px auto;
  background-color: var(--aside-background-color) !important;
  outline: none !important;
}

#onetrust-consent-sdk #onetrust-pc-sdk {
  background-color: var(--background) !important;
}

#onetrust-consent-sdk #ot-pc-title, #onetrust-consent-sdk #ot-pvcy-txt, #onetrust-consent-sdk #onetrust-pc-sdk #ot-pvcy-hdr, #onetrust-consent-sdk #onetrust-pc-sdk .ot-grp-desc, #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-policy-text, #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-policy-title {
  color: var(--font-color) !important;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-grp-desc a, #onetrust-consent-sdk #onetrust-pc-btn-handler {
  color: var(--link-color) !important;
}

#onetrust-consent-sdk #onetrust-pc-btn-handler {
  color: var(--link-color) !important;
  background-color: #0000 !important;
  outline: none !important;
}

#onetrust-consent-sdk .ot-title-cntr {
  width: calc(100% - 20px) !important;
  padding-top: 15px !important;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-active-menu {
  border-color: var(--color-primary) !important;
  background-color: var(--background) !important;
  color: var(--font-color) !important;
  outline: none !important;
}

#onetrust-consent-sdk .save-preference-btn-handler, #onetrust-consent-sdk #onetrust-accept-btn-handler {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
  outline: none !important;
}

#onetrust-consent-sdk .ot-pc-footer-logo {
  display: none;
}

#onetrust-pc-sdk .ot-pc-header, #onetrust-pc-sdk .ot-pc-footer {
  border-color: var(--border-color) !important;
}

.payment-table-container table {
  background: var(--background);
  color: var(--font-color);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "clig" off, "liga" off;
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.payment-table-container th, .payment-table-container td {
  border: 1px solid var(--border-color);
}

#payments-table > thead {
  z-index: 90;
  background-color: var(--background);
  position: sticky;
  top: 0;
}

.payment-table-container th, .payment-table-container td {
  padding: 15px;
  position: relative;
}

.payment-table-container tr td:first-child {
  font-weight: 600;
}

.preloader-container-table {
  z-index: 1;
  align-items: center;
  width: 100%;
  height: 500px;
  margin-top: 30px;
  position: absolute;
  overflow: hidden;
}

.hidden {
  display: none;
}

.show {
  display: block;
}

.loader-table {
  background-image: url("Ripple.3e397968.svg");
  background-repeat: no-repeat;
  width: 200px;
  height: 400px;
  margin: 176px auto 113px;
  top: 38px;
}

.loader-table:before, .loader-table:after {
  content: "";
  position: absolute;
}

.payment-table-container th:after, .payment-table-container td:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.payment-table-container th:before, .payment-table-container td:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.payment-table-container .show-more-button {
  cursor: pointer;
  color: var(--icon-color);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 8px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
}

.payment-table-container tr {
  width: 100%;
  height: 55px;
  left: 0;
}

#payments-table > tr:first-child {
  z-index: 90;
  background-color: var(--background);
  position: sticky;
  top: 0;
}

.payment-table-container .country-container {
  margin-bottom: 10px;
  display: block;
}

.payment-table-container th {
  color: var(--gray-text);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.payment-table-container table thead {
  background-color: var(--background);
  box-shadow: inset 0 -1px #e9e9e9;
}

.payment-table-container tbody {
  height: auto;
  overflow: auto;
}

.payment-table-container td {
  color: var(--gray-text);
  box-sizing: border-box;
  width: 150px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.payment-table-container .dropbtn {
  color: #fff;
  vertical-align: middle;
  cursor: pointer;
  float: right;
  background: url("filter-Icon.c2cbfdc6.svg") center no-repeat;
  border: none;
  padding: 8px 10px;
}

.payment-table-container td div {
  word-break: break-all;
}

.payment-table-container .features-container div {
  width: max-content;
}

.payment-table-container .features-container {
  width: 100%;
}

.check_text .features-text {
  color: var(--gray-text);
}

.payment-table-container .check {
  float: left;
  margin-right: 5 pxf;
  background: url("IconsV.5ff43de8.svg") no-repeat;
  align-items: self-start;
  width: 20px;
  height: 20px;
  padding: 10px;
}

.payment-table-container .flag-icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: var(--flag-url, none);
  vertical-align: bottom;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  display: inline-block;
}

.payment-table-container .delete {
  float: left;
  color: #676b73;
  background: url("IconsX.3ed4b64b.svg") no-repeat;
  align-items: self-start;
  width: 20px;
  height: 20px;
  padding: 10px;
}

.flag-text {
  width: 70%;
}

.payment-table-container .contentContainer {
  display: block;
}

.delete_text {
  color: #a4a6aa;
  margin-bottom: 5px;
  display: block;
}

.payment-table-container tr th span {
  font-weight: bold;
}

.payment-table-container .list-container {
  min-height: 70%;
  padding: 10px;
}

.payment-table-container .dropdown-content {
  background-color: var(--background);
  z-index: 1;
  border: 1px solid var(--border-color);
  z-index: 1;
  color: var(--gray-text);
  text-transform: capitalize;
  word-break: break-all;
  border-radius: 6px;
  width: 300px;
  height: 20vh;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: none;
  position: absolute;
  overflow: auto;
  box-shadow: 0 8px 16px #0003;
}

.payment-table-container .custom-checkbox {
  cursor: pointer;
  color: var(--gray-text);
  font-feature-settings: "clig" off, "liga" off;
  text-transform: capitalize;
  margin-bottom: 18px;
  padding-left: 35px;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: block;
  position: relative;
}

.payment-table-container .custom-checkbox input {
  opacity: 0;
  cursor: pointer;
  position: absolute;
}

.payment-table-container .checkmark {
  border: 1.5px solid var(--border-color);
  background: var(--background);
  border-radius: 3px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.payment-table-container .custom-checkbox input:checked ~ .checkmark {
  opacity: 1;
  background-color: var(--icon-color);
  content: "";
  color: var(--font-color);
  background-image: url("vIcon.aacbad65.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.payment-table-container #search-dropdown {
  border: 1px solid var(--border-color);
  background: url("searchIcon.242a2a59.svg") 6px 9px no-repeat;
  border-radius: 4px;
  outline: 0;
  width: 93%;
  height: 34px;
  margin: 10px 10px 12px;
  padding-left: 26px;
  display: block;
}

.payment-table-container #input_container {
  margin: 0;
  padding: 0;
  position: relative;
}

.payment-table-container .dropdown-content, .features-dropdown-content-container {
  display: none;
}

.bth-click {
  background: var(--icon-filtered);
  color: #fff;
  vertical-align: middle;
  cursor: pointer;
  float: right;
  border: none;
  padding: 8px 10px;
}

#content-wrapper {
  height: 100%;
}

.dropbtn {
  cursor: pointer;
}

.flag-text {
  display: inline-block;
}

.check_text {
  color: #a4a6aa;
  margin-bottom: 5px;
  display: block;
}

.reset-btn-container {
  z-index: 90;
  color: var(--icon-color);
  text-align: center;
  background-color: var(--background);
  border-top: 1px solid var(--border-color);
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
  height: 36px;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  position: sticky;
  bottom: 0;
  box-shadow: 0 8px 16px #0003;
}

.reset-btn {
  background: none;
}

.reset-btn-icon {
  background: var(--icon-reset);
  color: var(--icon-color);
  border: none;
  width: 16px;
  height: 16px;
  margin-top: 10px;
  margin-left: 20px;
}

.payment-table-container .dropdown-content input[type="checkbox"][value="all"] {
  font-weight: bold;
}

.payment-table-container .informaltable.table-responsive {
  width: 100%;
  height: 47vh;
  overflow: auto;
}

.payment-table-container table thead tr th {
  text-align: left;
  white-space: nowrap;
}

.site-header {
  display: block;
}

.site-header .logo {
  content: var(--logo);
}

.site-header .navbar-fixed-top {
  border-bottom: var(--aside-border);
  box-shadow: var(--aside-box-shadow);
  min-height: var(--header-height);
  background-color: var(--aside-background-color);
}

.site-header .navbar-brand {
  padding: 20px;
}

.site-header .navbar-toggle {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
}

.site-header-search {
  float: left;
  z-index: 2;
  width: 600px;
  margin-top: 15px;
  margin-left: 10px;
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.site-header .login-btn {
  width: 83px;
  position: absolute;
  top: 15px;
  right: 60px;
}

.toolbar {
  background-color: var(--background);
}

@media (width >= 768px) {
  .site-header .login-btn {
    right: 20px;
  }

  .site-header-search {
    width: 600px;
    display: block;
  }
}

@media (width <= 1050px) {
  .site-header-search {
    width: 300px;
  }
}

@media (width <= 389px) {
  .site-header .login-btn {
    width: 65px;
    right: 55px;
  }

  .site-header .logo {
    align-items: center;
    width: 90px;
    display: flex;
  }

  .site-header .navbar-brand {
    padding-top: 25px;
  }
}

.footer-content {
  display: none;
}

table.footer-container, .footer-container table, .footer-container {
  width: 100%;
  background-color: var(--footer-color) !important;
  margin-top: 15px !important;
}

div.footer-container {
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  height: var(--footer-height);
  overflow: hidden;
  width: 100% !important;
  position: absolute !important;
  bottom: 0 !important;
}

.footer-container tr td {
  vertical-align: top;
  border: none;
  padding: 0;
}

.footer-container .itemizedlist {
  margin: 0;
  line-height: 25px;
}

.footer-container .itemizedlist li {
  list-style: none;
}

.subscription-form {
  gap: 10px;
  margin-top: 15px;
  display: flex;
}

.subscription-form input {
  border-radius: 8px;
}

.subscription-form input:focus {
  border-color: var(--color-primary);
}

#bottom-pager, .site-footer {
  display: none;
}

.page-footer {
  min-height: var(--footer-height);
  width: 100%;
  margin-left: 0;
  position: sticky;
  overflow: hidden;
}

.hs-form-iframe {
  height: 110px !important;
}

@media (width >= 768px) {
  .page-footer {
    width: 75%;
    margin-left: 25%;
  }
}

@media (width >= 1200px) {
  .footer-container table tbody tr:first-child td:first-child {
    padding-left: 60px;
  }

  .page-footer {
    width: calc(100% - var(--sidebar-max-width));
    margin-left: var(--sidebar-max-width);
  }
}

@media (width >= 2000px) {
  .informaltable.footer-container {
    padding: 0 60px !important;
  }

  .footer-container table {
    width: 75%;
    margin: 0 auto;
  }
}

@media (width >= 576px) {
  .footer-container tr td:nth-child(2) {
    padding-top: 50px;
  }

  .hs-form-iframe {
    height: 200px !important;
  }
}

@media (width <= 576px) {
  .subscription-form {
    flex-direction: column;
  }

  .footer-container tr {
    flex-direction: column;
    display: flex;
  }

  .footer-container table tbody tr:first-child td:first-child {
    padding-left: 0;
  }

  div.footer-container {
    min-height: var(--mobile-footer-height);
  }

  .page-footer {
    min-height: 275px;
  }

  .footer-container tr td:first-child {
    padding: 15px 15px 0;
  }

  .footer-container tr td:nth-child(2) {
    padding: 0 15px 15px;
  }
}

@media (width <= 767px) {
  div.footer-container {
    bottom: auto !important;
  }
}

@media (width >= 768px) and (width <= 991px) {
  div.footer-container {
    bottom: 0 !important;
  }

  .page-footer, .footer-container {
    min-height: 280px;
  }
}

@media screen and (width <= 389px) {
  div.footer-container {
    font-size: var(--font-size-xs);
  }
}

.site-sidebar {
  top: var(--header-height);
  background-color: var(--aside-background-color);
  border-right: var(--aside-border);
  box-shadow: var(--aside-box-shadow);
  overflow: auto;
}

.site-sidebar.full {
  width: 100%;
}

.site-sidebar > ul > li > a {
  text-transform: uppercase;
  border-bottom: 1px solid var(--jumbotron-border-color);
  border-radius: 0;
}

.site-sidebar .logo, .collapsible-sidebar-nav .site-sidebar-header .navbar-toggle {
  display: none !important;
}

.site-sidebar-search {
  margin: 20px 10px 0;
  display: flex;
}

.toc .glyphicon:before {
  content: "";
}

.toc .opened > .topic-link > .glyphicon:before {
  content: "";
}

@media (width >= 1200px) {
  .site-content {
    width: calc(100% - var(--sidebar-max-width));
  }

  .site-sidebar {
    width: var(--sidebar-max-width);
  }
}

@media (width >= 768px) {
  .site-sidebar-search, .sidebar-search-dropdown {
    display: none;
  }
}

@media (width <= 576px) {
  .site-sidebar {
    width: 100%;
  }
}

main article {
  margin-top: 0;
}

section[id^="section-"]:before, section[id*="idm"]:before, dt:has(a.glossterm):before {
  content: "";
  visibility: hidden;
  height: 100px;
  margin-top: -100px;
  display: block;
}

pre {
  background-color: #0000 !important;
}

.site-content {
  margin-top: var(--header-height);
  float: left;
  margin-bottom: 50px;
  min-height: calc(100vh - var(--header-height)  - var(--footer-height)  - 50px) !important;
}

.site-content .toolbar {
  top: var(--header-height);
  z-index: 1;
  min-height: 30px;
  padding: 20px 0 20px 15px;
  position: sticky;
}

.site-content .separator {
  background-color: var(--jumbotron-border-color);
  width: 100%;
  height: 1px;
  margin: 40px 0;
}

.page-toc .section-nav-container {
  top: calc(var(--header-height)  + 64px);
}

.site-body .toolbar {
  box-shadow: none;
}

.site-body .toolbar .navbar-toggle {
  display: none;
}

main article {
  width: 100%;
  margin-left: 0 !important;
  padding: 0 60px !important;
}

img.materialboxed {
  border: 1px solid var(--jumbotron-border-color);
  max-width: 65%;
  max-height: 50vh;
}

@media (width >= 768px) {
  .site-content .toolbar {
    padding: 30px 0 30px 64px;
  }

  main article {
    margin-left: 0 !important;
  }
}

@media (width >= 1200px) {
  main article {
    margin-left: 0 !important;
  }

  .site-content {
    margin-left: var(--sidebar-max-width) !important;
  }
}

@media (width <= 767px) {
  .site-content {
    width: 100%;
  }

  main article {
    padding: 0 15px !important;
  }
}

@media (width <= 576px) {
  .site-content {
    margin-top: var(--header-height);
    float: left;
    min-height: calc(100vh - var(--header-height)  - 245px) !important;
  }
}

@media (width >= 2000px) {
  main {
    max-width: 70%;
    margin: 0 auto;
  }

  .general-text {
    max-width: 80%;
  }
}

.section.api-method-parameters + section > .titlepage {
  display: none;
}

.section.api-method-parameters .accordion ul {
  margin-bottom: 0 !important;
}

.section.api-method-parameters .accordion .accordion .panel-body:has(.inner-accordion) > div > div > ul {
  padding-bottom: 0 !important;
}

.section.api-method-parameters li {
  list-style: none;
}

.section.api-method-parameters .primary-description > li, .section.api-method-parameters .show-bullets > li {
  list-style-type: disc;
}

.section.api-method-parameters .accordion .panel-body {
  transition: none !important;
}

.section.api-method-parameters > div.titlepage {
  display: none;
}

.section.api-method-parameters .accordion .title {
  text-transform: none;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  margin-top: 5px !important;
}

.section.api-method-parameters .accordion .note .title, .section.api-method-parameters .accordion .caution .title, .section.api-method-parameters .accordion .warning .title, .section.api-method-parameters .accordion .tip .title {
  font-size: 13px;
}

.section.api-method-parameters .accordion .accordion .title {
  margin-top: 0 !important;
}

.section.api-method-parameters .accordion .panel {
  margin-top: 0;
  border-bottom-color: var(--jumbotron-border-color) !important;
}

.section.api-method-parameters .accordion .accordion .panel {
  margin-top: 1.5em;
}

.section.api-method-parameters .accordion .accordion .title > .title {
  font-size: var(--font-size);
  padding-left: 32px;
}

.section.api-method-parameters .accordion .accordion .panel-heading.active .title > .title {
  margin-bottom: 24px !important;
}

.section.api-method-parameters .accordion .panel-heading .title > .title:before {
  content: attr(collapse-status) " All";
  font-family: var(--font-family);
  color: var(--color-primary);
  font-weight: 400;
  font-size: var(--font-size-xs);
  justify-content: flex-end;
  width: 70px;
  display: flex;
}

.section.api-method-parameters .accordion .panel-body {
  margin-bottom: 0;
}

.section.api-method-parameters .accordion .accordion .panel-heading .title > .title:before {
  top: 4px;
  left: 3px;
  right: unset;
  content: "";
  font-family: fontello;
  font-size: var(--font-size-sm);
  color: var(--placeholder);
  display: block;
}

.section.api-method-parameters .accordion .accordion .panel-heading.active .title > .title:before {
  content: "";
}

.section.api-method-parameters .accordion .accordion .panel {
  border-top: 1px solid var(--jumbotron-border-color);
  margin: 0;
  padding: 20px 0;
}

.section.api-method-parameters .accordion .accordion .panel:has(.panel-heading.active) {
  padding-bottom: 0;
}

.section.parameters ul:has( > li.param-name) {
  border-top: 1px solid var(--jumbotron-border-color);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  color: var(--placeholder);
  padding: 10px 0;
  line-height: 20px;
  margin: 0 !important;
}

.section.parameters ul.primary-description, .section.parameters .param-description ul {
  padding: 0;
}

.section.parameters .param-description > .itemizedlist.primary-description > ul, .section.parameters .param-description > .itemizedlist.primary-description > div > ul, .section.parameters .param-description > .itemizedlist.show-bullets > ul, .section.parameters .param-description > .itemizedlist.show-bullets > div > ul, .section.parameters .param-name:has(.datatype-object) + .param-description > .itemizedlist > ul, .section.parameters .param-name:has(.datatype-object) + .param-description > div > .itemizedlist > ul, .section.parameters .param-name:has(.datatype-array) + .param-description > .itemizedlist > ul, .section.parameters .param-name:has(.datatype-array) + .param-description > div > .itemizedlist > ul {
  padding-left: 25px;
}

.section.parameters .panel-inner > .itemizedlist > ul, .section.parameters .panel-inner > div > .itemizedlist > ul {
  padding-left: 0;
}

.section.parameters ul > li.param-name {
  background-color: var(--literal-background);
  color: var(--font-color);
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 2px 5px;
  display: inline-flex;
}

.section.parameters ul > li.param-name:after {
  font-size: var(--font-size-xs);
  color: var(--placeholder);
  position: absolute;
}

.section.parameters ul > li.param-name:has(.required):before {
  content: "required";
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xxs);
  color: var(--danger-color);
  text-transform: uppercase;
  position: absolute;
}

.section.parameters ul > li.param-name:has(.datatype-object):after {
  content: "object";
  right: -50px;
}

.section.parameters ul > li.param-name:has(.datatype-object):before {
  right: -110px;
}

.section.parameters ul > li.param-name:has(.datatype-array):after {
  content: "array";
  right: -40px;
}

.section.parameters ul > li.param-name:has(.datatype-array):before {
  right: -100px;
}

.section.parameters ul > li.param-name:has(.datatype-boolean):after {
  content: "Boolean";
  right: -60px;
}

.section.parameters ul > li.param-name:has(.datatype-boolean):before {
  right: -120px;
}

.section.parameters ul > li.param-name:has(.datatype-number):after {
  content: "number";
  right: -55px;
}

.section.parameters ul > li.param-name:has(.datatype-number):before {
  right: -115px;
}

.section.parameters ul > li.param-name:has(.datatype-float):after {
  content: "float";
  right: -35px;
}

.section.parameters ul > li.param-name:has(.datatype-float):before {
  right: -95px;
}

.section.parameters ul > li.param-name:has(.datatype-int):after {
  content: "int";
  right: -35px;
}

.section.parameters ul > li.param-name:has(.datatype-int):before {
  right: -95px;
}

.section.parameters ul > li.param-name:has(.datatype-string):after {
  content: "string";
  right: -40px;
}

.section.parameters ul > li.param-name:has(.datatype-string):before {
  right: -100px;
}

.section.parameters .param-description .default-value {
  margin-top: 8px;
}

.section.parameters .param-description .default-value:before {
  content: "Default value: ";
  font-weight: var(--font-weight-bold);
}

@media (width >= 768px) {
  .section.api-method-parameters + section {
    height: 100%;
    position: sticky;
    top: 180px;
  }

  .section.api-method-parameters {
    padding-right: 40px;
  }
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container, .container-md, .container-sm {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  box-sizing: border-box;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  flex: none;
  width: auto;
}

.row-cols-1 > * {
  flex: none;
  width: 100%;
}

.row-cols-2 > * {
  flex: none;
  width: 50%;
}

.row-cols-3 > * {
  flex: none;
  width: 33.3333%;
}

.row-cols-4 > * {
  flex: none;
  width: 25%;
}

.row-cols-5 > * {
  flex: none;
  width: 20%;
}

.row-cols-6 > * {
  flex: none;
  width: 16.6667%;
}

.col-auto {
  flex: none;
  width: auto;
}

.col-1 {
  flex: none;
  width: 8.33333%;
}

.col-2 {
  flex: none;
  width: 16.6667%;
}

.col-3 {
  flex: none;
  width: 25%;
}

.col-4 {
  flex: none;
  width: 33.3333%;
}

.col-5 {
  flex: none;
  width: 41.6667%;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-7 {
  flex: none;
  width: 58.3333%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-9 {
  flex: none;
  width: 75%;
}

.col-10 {
  flex: none;
  width: 83.3333%;
}

.col-11 {
  flex: none;
  width: 91.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --bs-gutter-x: 0;
}

.g-0, .gy-0 {
  --bs-gutter-y: 0;
}

.g-1, .gx-1 {
  --bs-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --bs-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --bs-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5, .gy-5 {
  --bs-gutter-y: 3rem;
}

@media (width >= 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-sm-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
  }

  .col-sm-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-3 {
    flex: none;
    width: 25%;
  }

  .col-sm-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-sm-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-sm-6 {
    flex: none;
    width: 50%;
  }

  .col-sm-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-sm-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-sm-9 {
    flex: none;
    width: 75%;
  }

  .col-sm-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-sm-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-sm-12 {
    flex: none;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-md-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
  }

  .col-md-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }

  .col-md-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-md-9 {
    flex: none;
    width: 75%;
  }

  .col-md-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-md-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-md-12 {
    flex: none;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-lg-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
  }

  .col-lg-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-lg-9 {
    flex: none;
    width: 75%;
  }

  .col-lg-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-lg-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-lg-12 {
    flex: none;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
  }

  .col-xl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xl-6 {
    flex: none;
    width: 50%;
  }

  .col-xl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xl-9 {
    flex: none;
    width: 75%;
  }

  .col-xl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xxl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-auto {
    flex: none;
    width: auto;
  }

  .col-xxl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-3 {
    flex: none;
    width: 25%;
  }

  .col-xxl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xxl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xxl-6 {
    flex: none;
    width: 50%;
  }

  .col-xxl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xxl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xxl-9 {
    flex: none;
    width: 75%;
  }

  .col-xxl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xxl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xxl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 576px) {
  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }
}

@media (width >= 768px) {
  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }
}

@media (width >= 992px) {
  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }
}

@media (width >= 1200px) {
  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }
}

@media (width >= 1400px) {
  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

:root, [data-bs-theme="light"] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: #212529bf;
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: #21252980;
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 .5rem 1rem #00000026;
  --bs-box-shadow-sm: 0 .125rem .25rem #00000013;
  --bs-box-shadow-lg: 0 1rem 3rem #0000002d;
  --bs-box-shadow-inset: inset 0 1px 2px #00000013;
  --bs-focus-ring-width: .25rem;
  --bs-focus-ring-opacity: .25;
  --bs-focus-ring-color: #0d6efd40;
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme="dark"] {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
  --bs-body-color: #adb5bd;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: #adb5bdbf;
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: #adb5bd80;
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-border-color: #495057;
  --bs-border-color-translucent: #ffffff26;
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(13, 110, 253, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb, var(--bs-link-opacity, 1)));
  -webkit-text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1));
  -webkit-text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1));
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1));
}

.link-primary:focus, .link-primary:hover {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1));
  -webkit-text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1));
  -webkit-text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1));
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1));
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb, var(--bs-link-opacity, 1)));
  -webkit-text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1));
  -webkit-text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1));
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1));
}

.link-secondary:focus, .link-secondary:hover {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1));
  -webkit-text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1));
  -webkit-text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1));
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1));
}

.link-success {
  color: RGBA(var(--bs-success-rgb, var(--bs-link-opacity, 1)));
  -webkit-text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1));
  -webkit-text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1));
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1));
}

.link-success:focus, .link-success:hover {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1));
  -webkit-text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1));
  -webkit-text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1));
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1));
}

.link-info {
  color: RGBA(var(--bs-info-rgb, var(--bs-link-opacity, 1)));
  -webkit-text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1));
  -webkit-text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1));
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1));
}

.link-info:focus, .link-info:hover {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1));
  -webkit-text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1));
  -webkit-text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1));
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1));
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb, var(--bs-link-opacity, 1)));
  -webkit-text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1));
  -webkit-text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1));
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1));
}

.link-warning:focus, .link-warning:hover {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1));
  -webkit-text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1));
  -webkit-text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1));
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1));
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb, var(--bs-link-opacity, 1)));
  -webkit-text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1));
  -webkit-text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1));
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1));
}

.link-danger:focus, .link-danger:hover {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1));
  -webkit-text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1));
  -webkit-text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1));
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1));
}

.link-light {
  color: RGBA(var(--bs-light-rgb, var(--bs-link-opacity, 1)));
  -webkit-text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1));
  -webkit-text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1));
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1));
}

.link-light:focus, .link-light:hover {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1));
  -webkit-text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1));
  -webkit-text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1));
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1));
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb, var(--bs-link-opacity, 1)));
  -webkit-text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1));
  -webkit-text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1));
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1));
}

.link-dark:focus, .link-dark:hover {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1));
  -webkit-text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1));
  -webkit-text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1));
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1));
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1));
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1));
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1));
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1));
}

.link-body-emphasis:focus, .link-body-emphasis:hover {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, .75));
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, .75));
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, .75));
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, .75));
}

.focus-ring:focus {
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
  outline: 0;
}

.icon-link {
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, .5));
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, .5));
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, .5));
  text-underline-offset: .25em;
  backface-visibility: hidden;
  align-items: center;
  gap: .375rem;
  display: inline-flex;
}

.icon-link > .bi {
  fill: currentColor;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  transition: transform .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:focus-visible > .bi, .icon-link-hover:hover > .bi {
  transform: var(--bs-icon-link-transform, translate3d(.25em, 0, 0));
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (width >= 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption), .visually-hidden:not(caption) {
  position: absolute !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  -o-object-fit: contain !important;
  object-fit: contain !important;
}

.object-fit-cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}

.object-fit-fill {
  -o-object-fit: fill !important;
  object-fit: fill !important;
}

.object-fit-scale {
  -o-object-fit: scale-down !important;
  object-fit: scale-down !important;
}

.object-fit-none {
  -o-object-fit: none !important;
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: .1;
}

.border-opacity-25 {
  --bs-border-opacity: .25;
}

.border-opacity-50 {
  --bs-border-opacity: .5;
}

.border-opacity-75 {
  --bs-border-opacity: .75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: .25rem !important;
}

.row-gap-2 {
  row-gap: .5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  -moz-column-gap: 0 !important;
  column-gap: 0 !important;
}

.column-gap-1 {
  -moz-column-gap: .25rem !important;
  column-gap: .25rem !important;
}

.column-gap-2 {
  -moz-column-gap: .5rem !important;
  column-gap: .5rem !important;
}

.column-gap-3 {
  -moz-column-gap: 1rem !important;
  column-gap: 1rem !important;
}

.column-gap-4 {
  -moz-column-gap: 1.5rem !important;
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  -moz-column-gap: 3rem !important;
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: .25;
}

.text-opacity-50 {
  --bs-text-opacity: .5;
}

.text-opacity-75 {
  --bs-text-opacity: .75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10, .link-opacity-10-hover:hover {
  --bs-link-opacity: .1;
}

.link-opacity-25, .link-opacity-25-hover:hover {
  --bs-link-opacity: .25;
}

.link-opacity-50, .link-opacity-50-hover:hover {
  --bs-link-opacity: .5;
}

.link-opacity-75, .link-opacity-75-hover:hover {
  --bs-link-opacity: .75;
}

.link-opacity-100, .link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1, .link-offset-1-hover:hover {
  text-underline-offset: .125em !important;
}

.link-offset-2, .link-offset-2-hover:hover {
  text-underline-offset: .25em !important;
}

.link-offset-3, .link-offset-3-hover:hover {
  text-underline-offset: .375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0, .link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10, .link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: .1;
}

.link-underline-opacity-25, .link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: .25;
}

.link-underline-opacity-50, .link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: .5;
}

.link-underline-opacity-75, .link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: .75;
}

.link-underline-opacity-100, .link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (width >= 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: .25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: .5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }

  .column-gap-sm-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-sm-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-sm-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (width >= 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: .25rem !important;
  }

  .row-gap-md-2 {
    row-gap: .5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 3rem !important;
  }

  .column-gap-md-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-md-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-md-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (width >= 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: .25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: .5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }

  .column-gap-lg-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-lg-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-lg-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-xl-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-xl-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (width >= 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xxl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-xxl-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-xxl-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-xxl-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

@font-face {
  font-family: Inter;
  font-weight: 100;
  src: url("Inter-Thin.efc55e6c.ttf");
}

@font-face {
  font-family: Inter;
  font-weight: 200;
  src: url("Inter-ExtraLight.82484b71.ttf");
}

@font-face {
  font-family: Inter;
  font-weight: 300;
  src: url("Inter-Light.490626be.ttf");
}

@font-face {
  font-family: Inter;
  font-weight: 400;
  src: url("Inter-Regular.7cfebe77.ttf");
}

@font-face {
  font-family: Inter;
  font-weight: 500;
  src: url("Inter-Medium.775d466f.ttf");
}

@font-face {
  font-family: Inter;
  font-weight: 600;
  src: url("Inter-SemiBold.fa16e9b2.ttf");
}

@font-face {
  font-family: Inter;
  font-weight: 700;
  src: url("Inter-Bold.88a04ef3.ttf");
}

@font-face {
  font-family: fontello;
  src: url("fontello.32c8b1fc.eot");
  src: url("fontello.32c8b1fc.eot#iefix") format("embedded-opentype"), url("fontello.8250128a.woff2") format("woff2"), url("fontello.8b5283eb.woff") format("woff"), url("fontello.8b1738c3.ttf") format("truetype"), url("fontello.1bc68a4b.svg#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  speak: never;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  font-family: fontello;
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  font-size: var(--icon-size-xs);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  display: inline-block;
}

[class^="icon-"].sm:before, [class*=" icon-"].sm:before {
  font-size: var(--icon-size-sm);
}

[class^="icon-"].md:before, [class*=" icon-"].md:before {
  font-size: var(--icon-size-md);
}

[class^="icon-"].lg:before, [class*=" icon-"].lg:before {
  font-size: var(--icon-size-lg);
}

.icon-approve_balance:before {
  content: "";
}

.icon-certification:before {
  content: "";
}

.icon-nest:before {
  content: "";
}

.icon-reload_balance:before {
  content: "";
}

.icon-stepper:before {
  content: "";
}

.icon-more_vertical:before {
  content: "";
}

.icon-more_horizontal:before {
  content: "";
}

.icon-drag:before {
  content: "";
}

.icon-dollar:before {
  content: "";
}

.icon-resend:before {
  content: "";
}

.icon-app:before {
  content: "";
}

.icon-user_minus:before {
  content: "";
}

.icon-layers:before {
  content: "";
}

.icon-folder-1:before {
  content: "";
}

.icon-inbox:before {
  content: "";
}

.icon-like:before {
  content: "";
}

.icon-dislike:before {
  content: "";
}

.icon-close_2:before {
  content: "";
}

.icon-plus_2:before {
  content: "";
}

.icon-doc:before {
  content: "";
}

.icon-search-doc:before {
  content: "";
  margin: 10px 10px 0 0;
  font-size: 20px;
}

.icon-search-info_sq:before {
  content: "";
  margin: 10px 10px 0 0;
  font-size: 20px;
}

.icon-search-refresh:before {
  content: "";
  margin: 10px 10px 0 0;
  font-size: 20px;
}

.icon-search-icon-chevron_right:before {
  content: "";
  margin: 10px 10px 0 0;
  font-size: 20px;
}

.icon-info_sq:before {
  content: "";
}

.icon-refund:before {
  content: "";
}

.icon-refresh:before {
  content: "";
}

.icon-transactions:before {
  content: "";
}

.icon-chevron_right:before {
  content: "";
}

.icon-calculator-2:before {
  content: "";
}

.icon-chevron_left:before {
  content: "";
}

.icon-precntage:before {
  content: "";
}

.icon-hashtag:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-geteway:before {
  content: "";
}

.icon-users:before {
  content: "";
}

.icon-download:before {
  content: "";
}

.icon-bars:before {
  content: "";
}

.icon-merchant:before {
  content: "";
}

.icon-deactivate:before {
  content: "";
}

.icon-activate:before {
  content: "";
}

.icon-verify_identity:before {
  content: "";
}

.icon-simulate:before {
  content: "";
}

.icon-pin:before {
  content: "";
}

.icon-label:before {
  content: "";
}

.icon-download_transactions:before {
  content: "";
}

.icon-dispute:before {
  content: "";
}

.icon-add_payout:before {
  content: "";
}

.icon-add_iban:before {
  content: "";
}

.icon-accept:before {
  content: "";
}

.icon-gender:before {
  content: "";
}

.icon-fingerprint:before {
  content: "";
}

.icon-developers:before {
  content: "";
}

.icon-reserved-balance:before {
  content: "";
}

.icon-pending-balance:before {
  content: "";
}

.icon-available_balance:before {
  content: "";
}

.icon-cash-out:before {
  content: "";
}

.icon-cash-in:before {
  content: "";
}

.icon-notification-1:before {
  content: "";
}

.icon-activate-card:before {
  content: "";
}

.icon-dollar-transfer:before {
  content: "";
}

.icon-collect:before {
  content: "";
}

.icon-disburse:before {
  content: "";
}

.icon-world:before {
  content: "";
}

.icon-bulk:before {
  content: "";
}

.icon-comment:before {
  content: "";
}

.icon-status:before {
  content: "";
}

.icon-calendar-notification:before {
  content: "";
}

.icon-filter:before {
  content: "";
}

.icon-graph:before {
  content: "";
}

.icon-timer:before {
  content: "";
}

.icon-block:before {
  content: "";
}

.icon-show:before {
  content: "";
}

.icon-shield-off:before {
  content: "";
}

.icon-info_2:before {
  content: "";
}

.icon-bookmark:before {
  content: "";
}

.icon-chart:before {
  content: "";
}

.icon-folder:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-audit:before {
  content: "";
}

.icon-favorite:before {
  content: "";
}

.icon-user:before {
  content: "";
}

.icon-minus:before {
  content: "";
}

.icon-delete:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-minus_sq:before {
  content: "";
}

.icon-check_sq:before {
  content: "";
}

.icon-delete_sq:before {
  content: "";
}

.icon-add_sq:before {
  content: "";
}

.icon-doc_upload:before {
  content: "";
}

.icon-doc_download:before {
  content: "";
}

.icon-doc_minus:before {
  content: "";
}

.icon-doc_delete:before {
  content: "";
}

.icon-doc_plus:before {
  content: "";
}

.icon-chevron_c_left:before {
  content: "";
}

.icon-chevron_c_right:before {
  content: "";
}

.icon-chevron_c_up:before {
  content: "";
}

.icon-chevron_c_down:before {
  content: "";
}

.icon-chevron_up:before {
  content: "";
}

.icon-chevron_down:before {
  content: "";
}

.icon-chevron_s_left:before {
  content: "";
}

.icon-chevron_s_right:before {
  content: "";
}

.icon-chevron_s_up:before {
  content: "";
}

.icon-chevron_s_down:before {
  content: "";
}

.icon-arrow_s_down:before {
  content: "";
}

.icon-arrow_s_up:before {
  content: "";
}

.icon-arrow_s_left:before {
  content: "";
}

.icon-arrow_s_right:before {
  content: "";
}

.icon-arrow_down:before {
  content: "";
}

.icon-arrow_up:before {
  content: "";
}

.icon-arrow_left:before {
  content: "";
}

.icon-arrow_right:before {
  content: "";
}

.icon-save:before {
  content: "";
}

.icon-attach:before {
  content: "";
}

.icon-euro:before {
  content: "";
}

.icon-pound:before {
  content: "";
}

.icon-bitcoin:before {
  content: "";
}

.icon-warning:before {
  content: "";
}

.icon-warning_2:before {
  content: "";
}

.icon-calendar:before {
  content: "";
}

.icon-doc_main:before {
  content: "";
}

.icon-paypal:before {
  content: "";
}

.icon-server-1:before {
  content: "";
}

.icon-copy:before {
  content: "";
}

.icon-focus:before {
  content: "";
}

.icon-wifi_on:before {
  content: "";
}

.icon-wifi_off:before {
  content: "";
}

.icon-speed:before {
  content: "";
}

.icon-heart:before {
  content: "";
}

.icon-settings:before {
  content: "";
}

.icon-login:before {
  content: "";
}

.icon-board:before {
  content: "";
}

.icon-share_2:before {
  content: "";
}

.icon-loop:before {
  content: "";
}

.icon-building:before {
  content: "";
}

.icon-resize:before {
  content: "";
}

.icon-deploma:before {
  content: "";
}

.icon-crop:before {
  content: "";
}

.icon-shop:before {
  content: "";
}

.icon-plugin:before {
  content: "";
}

.icon-privacy:before {
  content: "";
}

.icon-bank_redirect:before {
  content: "";
}

.icon-business:before {
  content: "";
}

.icon-envelop:before {
  content: "";
}

.icon-menu_back:before {
  content: "";
}

.icon-360:before {
  content: "";
}

.icon-image:before {
  content: "";
}

.icon-cart:before {
  content: "";
}

.icon-chart-1:before {
  content: "";
}

.icon-trash:before {
  content: "";
}

.icon-hide:before {
  content: "";
}

.icon-edit:before {
  content: "";
}

.icon-location:before {
  content: "";
}

.icon-upload:before {
  content: "";
}

.icon-adjusments:before {
  content: "";
}

.icon-lock:before {
  content: "";
}

.icon-unlock:before {
  content: "";
}

.icon-keyboard:before {
  content: "";
}

.icon-cards:before {
  content: "";
}

.icon-home:before {
  content: "";
}

.icon-bag:before {
  content: "";
}

.icon-multi_cards:before {
  content: "";
}

.icon-credit_error:before {
  content: "";
}

.icon-chat:before {
  content: "";
}

.icon-credit_card:before {
  content: "";
}

.icon-sort_2:before {
  content: "";
}

.icon-open_link:before {
  content: "";
}

.icon-bank:before {
  content: "";
}

.icon-cash:before {
  content: "";
}

.icon-case:before {
  content: "";
}

.icon-screen:before {
  content: "";
}

.icon-wallet:before {
  content: "";
}

.icon-double-chevron-right:before {
  content: "";
}

.icon-double-chevron-left:before {
  content: "";
}

.icon-complete_refund:before {
  content: "";
}

.icon-shield:before {
  content: "";
}

.icon-camera:before {
  content: "";
}

.icon-selfie:before {
  content: "";
}

.icon-add_user:before {
  content: "";
}

.icon-coin:before {
  content: "";
}

.icon-lock-filled:before {
  content: "";
}

.icon-tool:before {
  content: "";
}

.icon-tasks:before {
  content: "";
}

.icon-text:before {
  content: "";
}

.icon-support:before {
  content: "";
}

.icon-disable:before {
  content: "";
}

.icon-sort:before {
  content: "";
}

.icon-shopping:before {
  content: "";
}

.icon-server:before {
  content: "";
}

.icon-sandbox:before {
  content: "";
}

.icon-sales:before {
  content: "";
}

.icon-question-mark:before {
  content: "";
}

.icon-print:before {
  content: "";
}

.icon-polygon:before {
  content: "";
}

.icon-pocket:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-pen:before {
  content: "";
}

.icon-package:before {
  content: "";
}

.icon-options:before {
  content: "";
}

.icon-more_square:before {
  content: "";
}

.icon-more_circular:before {
  content: "";
}

.icon-mobile:before {
  content: "";
}

.icon-link:before {
  content: "";
}

.icon-id:before {
  content: "";
}

.icon-hardrive:before {
  content: "";
}

.icon-desktop:before {
  content: "";
}

.icon-cpu:before {
  content: "";
}

.icon-Compliance:before {
  content: "";
}

.icon-command:before {
  content: "";
}

.icon-color_picker:before {
  content: "";
}

.icon-coffee:before {
  content: "";
}

.icon-clock:before {
  content: "";
}

.icon-clock_sq:before {
  content: "";
}

.icon-change_direction:before {
  content: "";
}

.icon-calculator:before {
  content: "";
}

.icon-burger:before {
  content: "";
}

.icon-book:before {
  content: "";
}

.icon-bold:before {
  content: "";
}

.icon-archive:before {
  content: "";
}

.icon-notification:before {
  content: "";
}

.icon-add_credit_card:before {
  content: "";
}

.icon-user-minus2:before {
  content: "";
}

.icon-wallets-added:before {
  content: "";
}

.icon-equal:before {
  content: "";
}

.icon-not_equal:before {
  content: "";
}

.icon-moon:before {
  content: "";
}

.icon-sun:before {
  content: "";
}
/*# sourceMappingURL=main.css.map */
