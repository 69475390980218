.section.api-method-parameters + section > .titlepage {
  display: none;
}

.section.api-method-parameters .accordion ul {
  margin-bottom: 0 !important;
}

.section.api-method-parameters
  .accordion
  .accordion
  .panel-body:has(.inner-accordion)
  > div
  > div
  > ul {
  padding-bottom: 0 !important;
}

.section.api-method-parameters li {
  list-style: none;
}

.section.api-method-parameters .primary-description > li,
.section.api-method-parameters .show-bullets > li {
  list-style-type: disc;
}

.section.api-method-parameters .accordion .panel-body {
  transition: none !important;
}

.section.api-method-parameters > div.titlepage {
  display: none;
}

.section.api-method-parameters .accordion .title {
  text-transform: none;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  margin-top: 5px !important;
}

.section.api-method-parameters .accordion .note .title,
.section.api-method-parameters .accordion .caution .title,
.section.api-method-parameters .accordion .warning .title,
.section.api-method-parameters .accordion .tip .title {
  font-size: 13px;
}

.section.api-method-parameters .accordion .accordion .title {
  margin-top: 0 !important;
}

.section.api-method-parameters .accordion .panel {
  border-bottom-color: var(--jumbotron-border-color) !important;
  margin-top: 0;
}

.section.api-method-parameters .accordion .accordion .panel {
  margin-top: 1.5em;
}

.section.api-method-parameters .accordion .accordion .title > .title {
  padding-left: 32px;
  font-size: var(--font-size);
}

.section.api-method-parameters
  .accordion
  .accordion
  .panel-heading.active
  .title
  > .title {
  margin-bottom: 24px !important;
}

.section.api-method-parameters
  .accordion
  .panel-heading
  .title
  > .title:before {
  content: attr(collapse-status) ' All';
  font-family: var(--font-family);
  width: 70px;
  display: flex;
  justify-content: flex-end;
  color: var(--color-primary);
  font-weight: 400;
  font-size: var(--font-size-xs);
}

.section.api-method-parameters .accordion .panel-body {
  margin-bottom: 0;
}

.section.api-method-parameters
  .accordion
  .accordion
  .panel-heading
  .title
  > .title:before {
  display: block;
  left: 3px;
  top: 4px;
  right: unset;
  content: '\e875';
  font-family: fontello;
  font-size: var(--font-size-sm);
  color: var(--placeholder);
}

.section.api-method-parameters
  .accordion
  .accordion
  .panel-heading.active
  .title
  > .title:before {
  content: '\e874';
}

.section.api-method-parameters .accordion .accordion .panel {
  margin: 0;
  padding: 20px 0;
  border-top: 1px solid var(--jumbotron-border-color);
}

.section.api-method-parameters
  .accordion
  .accordion
  .panel:has(.panel-heading.active) {
  padding-bottom: 0;
}

.section.parameters ul:has(> li.param-name) {
  margin: 0 !important;
  border-top: 1px solid var(--jumbotron-border-color);
  padding: 10px 0 10px 0;
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: 20px;
  color: var(--placeholder);
}

.section.parameters ul.primary-description,
.section.parameters .param-description ul {
  padding: 0;
}

.section.parameters
  .param-name:has(.datatype-object)
  + .param-description
  > .itemizedlist
  > ul,
.section.parameters
  .param-name:has(.datatype-object)
  + .param-description
  > div
  > .itemizedlist
  > ul,
.section.parameters
  .param-name:has(.datatype-array)
  + .param-description
  > .itemizedlist
  > ul,
.section.parameters
  .param-name:has(.datatype-array)
  + .param-description
  > div
  > .itemizedlist
  > ul,
.section.parameters .param-description > .itemizedlist.primary-description > ul,
.section.parameters
  .param-description
  > .itemizedlist.primary-description
  > div
  > ul,
.section.parameters .param-description > .itemizedlist.show-bullets > ul,
.section.parameters .param-description > .itemizedlist.show-bullets > div > ul {
  padding-left: 25px;
}

.section.parameters .panel-inner > .itemizedlist > ul,
.section.parameters .panel-inner > div > .itemizedlist > ul {
  padding-left: 0;
}

.section.parameters ul > li.param-name {
  background-color: var(--literal-background);
  color: var(--font-color);
  padding: 2px 5px;
  display: inline-flex;
  margin-bottom: 10px;
  border-radius: 4px;
}

.section.parameters ul > li.param-name:after {
  position: absolute;
  font-size: var(--font-size-xs);
  color: var(--placeholder);
}

.section.parameters ul > li.param-name:has(.required):before {
  position: absolute;
  content: 'required';
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xxs);
  color: var(--danger-color);
  text-transform: uppercase;
}

.section.parameters ul > li.param-name:has(.datatype-object):after {
  content: 'object';
  right: -50px;
}

.section.parameters ul > li.param-name:has(.datatype-object):before {
  right: -110px;
}

.section.parameters ul > li.param-name:has(.datatype-array):after {
  content: 'array';
  right: -40px;
}

.section.parameters ul > li.param-name:has(.datatype-array):before {
  right: -100px;
}

.section.parameters ul > li.param-name:has(.datatype-boolean):after {
  content: 'Boolean';
  right: -60px;
}

.section.parameters ul > li.param-name:has(.datatype-boolean):before {
  right: -120px;
}

.section.parameters ul > li.param-name:has(.datatype-number):after {
  content: 'number';
  right: -55px;
}

.section.parameters ul > li.param-name:has(.datatype-number):before {
  right: -115px;
}

.section.parameters ul > li.param-name:has(.datatype-float):after {
  content: 'float';
  right: -35px;
}

.section.parameters ul > li.param-name:has(.datatype-float):before {
  right: -95px;
}

.section.parameters ul > li.param-name:has(.datatype-int):after {
  content: 'int';
  right: -35px;
}

.section.parameters ul > li.param-name:has(.datatype-int):before {
  right: -95px;
}

.section.parameters ul > li.param-name:has(.datatype-string):after {
  content: 'string';
  right: -40px;
}

.section.parameters ul > li.param-name:has(.datatype-string):before {
  right: -100px;
}

.section.parameters .param-description .default-value {
  margin-top: 8px;
}

.section.parameters .param-description .default-value:before {
  content: 'Default value: ';
  font-weight: var(--font-weight-bold);
}

@media (min-width: 768px) {
  .section.api-method-parameters + section {
    height: 100%;
    position: sticky;
    top: 180px;
  }

  .section.api-method-parameters {
    padding-right: 40px;
  }
}
