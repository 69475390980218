:root {
  --font-size: 14px;
  --font-size-sm: 16px;
  --font-size-xs: 12px;
  --font-size-md: 18px;
  --font-size-xxs: 10px;
  --font-weight: 400;
  --font-weight-bolder: 500;
  --font-weight-bold: 600;
  --line-height: 24px;
  --font-family: Inter, sans-serif;
  --icon-size-xs: 16px;
  --icon-size-sm: 18px;
  --icon-size-md: 24px;
  --icon-size-lg: 32px;
  --header-height: 64px;
  --footer-height: 198px;
  --mobile-footer-height: 275px;
  --warning-color: #ffac4d;
  --info-color: #62affa;
  --danger-color: #f25050;
  --success-color: #1fd01f;
  --sidebar-max-width: 324px;
}