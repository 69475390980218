.breadcrumb {
  font-size: var(--font-size);
}

.breadcrumb .breadcrumb-link a {
  color: var(--placeholder);
}

.breadcrumb .breadcrumb-link a:hover,
.breadcrumb .breadcrumb-node {
  color: var(--link-color);
}

.breadcrumb > li + li:before {
  color: var(--placeholder);
  font-family: fontello;
  content: '\e818';
  padding: 0 10px;
  margin-left: -5px;
}

@media (min-width: 2000px) {
  .breadcrumb-container {
    max-width: 70%;
    margin: 0 auto;
  }
}
