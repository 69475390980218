@font-face {
  font-family: Inter;
  font-weight: 100;
  src: url('../Inter/Inter-Thin.ttf');
}

@font-face {
  font-family: Inter;
  font-weight: 200;
  src: url('../Inter/Inter-ExtraLight.ttf');
}

@font-face {
  font-family: Inter;
  font-weight: 300;
  src: url('../Inter/Inter-Light.ttf');
}

@font-face {
  font-family: Inter;
  font-weight: 400;
  src: url('../Inter/Inter-Regular.ttf');
}

@font-face {
  font-family: Inter;
  font-weight: 500;
  src: url('../Inter/Inter-Medium.ttf');
}

@font-face {
  font-family: Inter;
  font-weight: 600;
  src: url('../Inter/Inter-SemiBold.ttf');
}

@font-face {
  font-family: Inter;
  font-weight: 700;
  src: url('../Inter/Inter-Bold.ttf');
}
