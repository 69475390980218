.nav-site-sidebar {
    padding: 0 8px 0 12px;
}

.nav-site-sidebar li a {
    color: var(--font-color);
    border-radius: 4px;
    margin-bottom: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.nav-site-sidebar li.active > a,
.nav-site-sidebar li a:hover {
    background-color: var(--nav-item-hover-background);
    transition: background-color ease-in-out 0.2s;
}

.nav-site-sidebar li.active > a,
.nav-site-sidebar li:has(li.active) > a {
    color: var(--link-color) !important;
    font-weight: 500;
}

.nav-site-sidebar > li > a {
    font-weight: 600;
    font-size: var(--font-size-xs);
}

.nav-site-sidebar [class^='icon-']:before,
.nav-site-sidebar [class*=' icon-']:before {
    position: absolute;
    left: 10px;
    bottom: 10px;
}

.toc > li > .topic-link > .glyphicon {
    margin-top: 4px;
}

.nav-site-sidebar .active > a {
    color: var(--link-color) !important;
}

.nav-site-sidebar ul ul ul ul a {
    padding-left: 75px
}