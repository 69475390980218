.list-table,
.list-table table {
  width: 100%;
  table-layout: fixed;
}
.list-table th {
  border-top: none;
}
.list-table th,
.list-table td {
  padding: 30px;
  border-color: var(--jumbotron-border-color);
}
.list-table th p,
.list-table td p {
  font-style: normal;
  font-weight: 400;
}
.list-table th a,
.list-table td a {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-transform: capitalize;
  color: var(--color-primary);
}
.list-table th ul,
.list-table td ul {
  margin-top: 15px;
  padding: 0;
}
.list-table th ul li,
.list-table td ul li {
  padding: 3px 0;
  list-style: none;
}
.list-table th ul li a,
.list-table td ul li a {
  color: var(--color-primary);
}
.list-table tr:last-child td {
  border-bottom: none;
}
.error-list > ul, .error-codes > ul {
  list-style: none !important;
  padding-left: 0;
}
.error-list > ul > li > p:nth-child(2), .error-codes > ul > li > p:nth-child(2) {
  padding-left: 15px;
}
.error-list table, .error-list, .error-codes table, .error-codes {
  margin-top: 15px;
}
.error-list table .h4, .error-list .h4, .error-codes table .h4, .error-codes .h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.error-list table td, .error-codes table td {
  padding-bottom: 15px;
  border: none;
}
.error-list table td p:not(:first-child), .error-codes table td p:not(:first-child) {
  padding-left: 15px;
}
.error-list.active, .error-codes.active {
  display: block !important;
}
@media (max-width: 767px) {
  .list-table tr {
    display: flex;
    flex-direction: column;
  }
}
