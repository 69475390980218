.link-arrow {
  color: var(--color-primary);
}
.link-arrow:after {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\e881';
}
.jumbotron-container {
  margin-top: 30px;
  border: 1px solid var(--jumbotron-border-color);
  border-radius: 8px;
  padding: 24px 15px 15px 24px;
  background-color: var(--jumbotron-background);
}
.jumbotron-container a {
  margin-top: 5px;
  display: inline-block;
}
.jumbotron-container .section-title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px !important;
  margin-top: 0 !important;
}
.jumbotron-container .titlepage {
  display: none;
}
a.link {
  color: var(--link-color) !important;
}
a.btn {
  color: var(--btn-text-color) !important;
}
@media (min-width: 500px) {
  .page-toc .pager,
  .page-toc .site-footer,
  .page-toc main article {
    width: 100%;
  }

  .page-toc main:has(.section-nav-container) article {
    width: 80% !important;
  }
}

section[id*='idm']:before {
  content: '';
  /* height: 100px; */
  height: 0 !important;
  visibility: hidden;
  /* margin-top: -100px; */
  margin-top: 0 !important;
  display: block;
}
