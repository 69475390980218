:root {
    --background: white;
    --font-color: #2e2e2e;
    --color-primary: #7437f5;
    --card-background: white;
    --card-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    --box-background: #f9f9f9;
    --shadow-color: rgba(0, 0, 0, 0.07);
    --btn-hover-color: #7437f5;
    --btn-text-color: white;
    --border-color: #dfdfdf;
    --nav-item-hover-background: #f6f6f7;
    --literal-background: #ebebeb;
    --placeholder: #676568;
    --jumbotron-border-color: #d9d9d9;
    --notification-font-color: #222024;
    --dark-text: #555555;
    --logo: url(/css/assets/images/logo.svg);
    --empty-state: url(/css/assets/images/empty-state.svg);
    --code-string-color: #0da5d7;
    --code-literal-color: #48b901;
    --code-number-color: #7437f5;
    --icon-color: #262327;
    --icon-font-color: #929193;
    --aside-border: 1px solid var(--border-color);
    --aside-box-shadow: none;
    --aside-background-color: white;
    --color-put: #c5862b;
    --color-post-text: #0DBA50;
    --color-post: rgba(13, 186, 80, 0.10);
    --color-get: rgba(9, 136, 239, 0.10);
    --color-get-text: #0988EF;
    --color-put: rgba(197, 134, 43, 0.15);
    --color-delete-text: #f2292f;
    --color-delete: rgba(242, 41, 47, 0.10);
    --footer-color: #f9f9f9;
    --jumbotron-background: #ffffff;
    --success-bg: #eefbee;
    --info-bg: #eff7fe;
    --warning-bg: #fff7ed;
    --danger-bg: #fff5f5;
    --link-color: #7437f5;
    --color-branding-secondary: #ff007a;
    --code-attribute-color: #000000;
    --category-background-color: #F9F9F9;
    --all-results-border: #D7D8DB;
    --mark-results: #E8Dfff;
    --dropdown-background: #FFFFFF;
    --pagination-border-color: #E8DEFF;
    --disabled-color: #909091;
    --result-hover-background: #F6F2FF;
    --gray-text: #676B73;
    --hover-background-color: #F6F6F6;
    --hover-background-color-icon: #454545;
    --icon-number-1: url(/css/assets/images/1-icon-light.svg) 0px -1px no-repeat;
    --icon-number-2: url(/css/assets/images/2-icon-light.svg) 0px -1px no-repeat;
    --icon-number-3: url(/css/assets/images/3-icon-light.svg) 0px -1px no-repeat;
    --icon-number-4: url(/css/assets/images/4-icon-light.svg) 0px -1px no-repeat;
    --icon-number-5: url(/css/assets/images/5-icon-light.svg) 0px -1px no-repeat;
    --icon-number-6: url(/css/assets/images/6-icon-light.svg) 0px -1px no-repeat;
    --icon-number-7: url(/css/assets/images/7-icon-light.svg) 0px -1px no-repeat;
    --icon-number-8: url(/css/assets/images/8-icon-light.svg) 0px -1px no-repeat;
    --icon-number-9: url(/css/assets/images/9-icon-light.svg) 0px -1px no-repeat;
    --icon-number-10: url(/css/assets/images/10-icon-light.svg) 0px -1px no-repeat;
    --icon-filtered: url(/css/assets/images/WithValues-filter-icon.svg) no-repeat center;
    --icon-reset: url(/css/assets/images/refresh.svg);
}

html[data-theme='default'] {
    --background: white;
    --font-color: #2e2e2e;
    --color-primary: #7437f5;
    --card-background: white;
    --card-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    --box-background: #f9f9f9;
    --shadow-color: rgba(0, 0, 0, 0.07);
    --btn-hover-color: #7437f5;
    --btn-text-color: white;
    --border-color: #dfdfdf;
    --nav-item-hover-background: #f6f6f7;
    --literal-background: #ebebeb;
    --placeholder: #676568;
    --jumbotron-border-color: #d9d9d9;
    --notification-font-color: #222024;
    --dark-text: #555555;
    --logo: url(/css/assets/images/logo.svg);
    --empty-state: url(/css/assets/images/empty-state.svg);
    --code-string-color: #0da5d7;
    --code-literal-color: #48b901;
    --code-number-color: #7437f5;
    --icon-color: #262327;
    --icon-font-color: #929193;
    --icon-background-color: #f9f9f9;
    --aside-border: 1px solid var(--border-color);
    --aside-box-shadow: none;
    --aside-background-color: white;
    --color-put: #c5862b;
    --color-post-text: #0DBA50;
    --color-post: rgba(13, 186, 80, 0.15);
    --color-get: rgba(9, 136, 239, 0.15);
    --color-get-text: #0f6ab4;
    --color-put: rgba(197, 134, 43, 0.15);
    --color-delete-text: #a41e22;
    --color-delete: rgba(242, 41, 47, 0.15);
    --footer-color: #f9f9f9;
    --jumbotron-background: #ffffff;
    --success-bg: #eefbee;
    --info-bg: #eff7fe;
    --warning-bg: #fff7ed;
    --danger-bg: #fff5f5;
    --link-color: #7437f5;
    --color-branding-secondary: #ff007a;
    --code-attribute-color: #000000;
    --mark-results: #E8Dfff;
    --category-background-color: #F9F9F9;
    --all-results-border: #D7D8DB;
    --dropdown-background: #FFFFFF;
    --pagination-border-color: #E8DEFF;
    --disabled-color: #909091;
    --hover-background-color: #F6F6F6;
    --result-hover-background: #F6F2FF;
    --gray-text: #676B73;
    --hover-background-color-icon: #454545;
    --icon-number-1: url(/css/assets/images/1-icon-light.svg) 0px -1px no-repeat;
    --icon-number-2: url(/css/assets/images/2-icon-light.svg) 0px -1px no-repeat;
    --icon-number-3: url(/css/assets/images/3-icon-light.svg) 0px -1px no-repeat;
    --icon-number-4: url(/css/assets/images/4-icon-light.svg) 0px -1px no-repeat;
    --icon-number-5: url(/css/assets/images/5-icon-light.svg) 0px -1px no-repeat;
    --icon-number-6: url(/css/assets/images/6-icon-light.svg) 0px -1px no-repeat;
    --icon-number-7: url(/css/assets/images/7-icon-light.svg) 0px -1px no-repeat;
    --icon-number-8: url(/css/assets/images/8-icon-light.svg) 0px -1px no-repeat;
    --icon-number-9: url(/css/assets/images/9-icon-light.svg) 0px -1px no-repeat;
    --icon-number-10: url(/css/assets/images/10-icon-light.svg) 0px -1px no-repeat;
    --icon-filtered: url(/css/assets/images/WithValues-filter-icon.svg) no-repeat center;
    --icon-reset: url(/css/assets/images/refresh.svg);
}
