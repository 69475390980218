@import 'reset.css';
@import 'constants/index.css';
@import 'typography.css';
@import 'utilities.css';

html, body {
  height: 100%;
}

body {
  background-color: var(--background);
  color: var(--font-color);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--line-height);
  font-family: var(--font-family);
}