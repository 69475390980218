.query-params-table {
  margin-bottom: 2em;
}

.query-params-table table {
  width: 100%;
}

.query-params-table th {
  border-top: none;
  font-weight: var(--font-weight-bold);
}

.query-params-table th, .query-params-table td {
  padding: 14px 20px;
}

.query-params-table td:first-child {
  border-left-style: solid;
}

.query-params-table td:last-child {
  border-right-style: solid;
}

.query-params-table .query-param {
  background-color: var(--nav-item-hover-background);
  padding: 4px 8px;
  border-radius: 100px;
}
