#feedback-modal .close {
  top: 5px;
  right: 5px;
}
#feedback-modal label {
  font-weight: 500;
}
.modal-content {
  background-color: var(--background);
}
.modal-title {
  color: var(--font-color);
}
.modal-footer,
.modal-header {
  border-color: var(--jumbotron-border-color);
}
.close {
  color: var(--font-color);
}