.popover {
  font-family: var(--font-family);
  font-size: var(--font-size-xs);
  color: var(--font-color);
  background-color: var(--aside-background-color);
}

.popover .popover-title {
  background-color: var(--aside-background-color);
  padding-top: 15px;
  padding-bottom: 0;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  border-bottom: none;
}

.popover .popover-content {
  padding-bottom: 0;
}

.popover .arrow::after {
  border-bottom-color: var(--aside-background-color) !important;
}