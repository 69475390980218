main article {
  margin-top: 0;
}

/* Do not remove! The fix for offset when moving to the anchor */
dt:has(a.glossterm):before, section[id^='section-']:before, section[id*='idm']:before {
  content: '';
  display: block;
  height: 100px;
  margin-top: -100px;
  visibility: hidden;
}

pre {
  background-color: transparent !important;
}

.site-content {
  margin-top: var(--header-height);
  float: left;
  min-height: calc(100vh - var(--header-height) - var(--footer-height) - 50px) !important;
  margin-bottom: 50px;
}

.site-content .toolbar {
  position: sticky;
  top: var(--header-height);
  z-index: 1;
  padding: 20px 0 20px 15px;
  min-height: 30px;
}

.site-content .separator {
  width: 100%;
  height: 1px;
  background-color: var(--jumbotron-border-color);
  margin: 40px 0;
}

.page-toc .section-nav-container {
  top: calc(var(--header-height) + 64px);
}

.site-body .toolbar {
  box-shadow: none;
}

.site-body .toolbar .navbar-toggle {
  display: none;
}

main article {
  margin-left: 0 !important;
  width: 100%;
  padding: 0 60px !important;
}

img.materialboxed {
  border: 1px solid var(--jumbotron-border-color);
  max-width: 65%;
  max-height: 50vh;
}

@media (min-width: 768px) {
  .site-content .toolbar {
    padding: 30px 0 30px 64px;
  }

  main article {
    margin-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  main article {
    margin-left: 0 !important;
  }

  .site-content {
    margin-left: var(--sidebar-max-width) !important;
  }
}

@media (max-width: 767px) {
  .site-content {
    width: 100%;
  }

  main article {
    padding: 0 15px !important;
  }
}

@media (max-width: 576px) {
  .site-content {
    margin-top: var(--header-height);
    float: left;
    min-height: calc(100vh - var(--header-height) - 245px) !important;
  }
}

@media (min-width: 2000px) {
  main {
    margin: 0 auto;
    max-width: 70%;
  }

  .general-text {
    max-width: 80%;
  }
}
