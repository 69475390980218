.inner-accordion > p {
  position: relative;
  cursor: pointer;
  margin-bottom: 40px;
}

.inner-accordion > p:after {
  position: absolute;
  bottom: -30px;
  font-family: fontello;
  font-size: var(--font-size-sm);
  margin-right: 8px;
  content: '\e875';
  left: 0;
}

.inner-accordion > p:before {
  position: absolute;
  bottom: -30px;
  content: 'Show child parameters';
  left: 25px;
  font-size: var(--font-size-xs);
}

.inner-accordion[aria-expanded='true'] > p:after {
  content: '\e874';
}

.inner-accordion[aria-expanded='true'] > p:before {
  content: 'Hide child parameters';
}