.btn {
  border-radius: 8px;
  font-weight: 500;
  font-size: var(--font-size);
  line-height: 20px;
  cursor: pointer;
}
.btn-primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--btn-text-color);
}
.btn-primary-arrow {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--btn-text-color);
}
.btn-primary:hover, .btn-primary-arrow:hover,
.btn-primary:active, .btn-primary-arrow:active,
.btn-primary:focus, .btn-primary-arrow:focus {
  background-color: var(--btn-hover-color) !important;
  border-color: var(--btn-hover-color) !important;
  color: var(--btn-text-color) !important;
}
.btn-primary-arrow:after {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\e881';
}
