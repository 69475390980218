.section-nav.nav li > a {
  color: var(--font-color);
}

.section-nav.nav li.active > a {
  color: var(--color-primary) !important;
}

.section-nav.nav li.active > a:before {
  display: none;
}

.section-nav.nav>li:first-child {
  display: none;
}

.section-nav.nav>li:nth-child(2) {
  margin-top: 15px;
}